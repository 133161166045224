/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCreatePartnerDtoGen,
  ThgGeojsonPointViewmodelGen,
  ThgMultiplePartnersFindManyIdsGen,
  ThgPartnerControllerFindAllParamsGen,
  ThgPartnerControllerFindByNameGeocodeParamsGen,
  ThgPartnerControllerFindByNameQueryParamsGen,
  ThgPartnerControllerFindByUrlParamsGen,
  ThgPartnerControllerGetGeocodeParamsGen,
  ThgPartnerDocumentGen,
  ThgPartnerGeojsonViewmodelGen,
  ThgPartnerViewModelGen,
  ThgUpdatePartnerCompanyUserNameGen,
  ThgUpdatePartnerDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Partner<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerCreate
   * @summary Create a new partner
   * @request POST:/partner
   * @secure
   * @response `200` `ThgPartnerViewModelGen` The record has been successfully created
   * @response `403` `void` Forbidden.
   */
  partnerControllerCreate = (data: ThgCreatePartnerDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindAll
   * @summary Get all partners
   * @request GET:/partner
   * @secure
   * @response `200` `(ThgPartnerViewModelGen)[]` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerFindAll = (query: ThgPartnerControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPartnerViewModelGen[], void>({
      path: `/partner`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindAllPartnersForAnalyticsPermission
   * @summary Get partners for analytics permission
   * @request POST:/partner/byAnalyticsPermissionIds
   * @secure
   * @response `200` `(ThgPartnerDocumentGen)[]` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerFindAllPartnersForAnalyticsPermission = (
    data: ThgMultiplePartnersFindManyIdsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPartnerDocumentGen[], void>({
      path: `/partner/byAnalyticsPermissionIds`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindGeojson
   * @summary Get all partners
   * @request GET:/partner/geojson
   * @secure
   * @response `200` `ThgPartnerGeojsonViewmodelGen` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerFindGeojson = (params: RequestParams = {}) =>
    this.http.request<ThgPartnerGeojsonViewmodelGen, void>({
      path: `/partner/geojson`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindByNameQuery
   * @summary Get a partner by name
   * @request GET:/partner/byName
   * @response `200` `ThgPartnerViewModelGen` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerFindByNameQuery = (
    query: ThgPartnerControllerFindByNameQueryParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/byName`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindByUrl
   * @summary Get a partner by url
   * @request GET:/partner/byUrl
   * @response `200` `ThgPartnerViewModelGen` The found record
   * @response `400` `void` Bad request
   * @response `404` `void` No partner with that url was found
   */
  partnerControllerFindByUrl = (query: ThgPartnerControllerFindByUrlParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/byUrl`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindByNameGeocode
   * @summary Get a partner by location search string
   * @request GET:/partner/byLocation
   * @secure
   * @response `200` `ThgPartnerViewModelGen` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerFindByNameGeocode = (
    query: ThgPartnerControllerFindByNameGeocodeParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/byLocation`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerGetGeocode
   * @summary Gets the geocode for an address.
   * @request GET:/partner/geocodehelper
   * @secure
   * @response `200` `ThgGeojsonPointViewmodelGen` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerGetGeocode = (query: ThgPartnerControllerGetGeocodeParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgGeojsonPointViewmodelGen, void>({
      path: `/partner/geocodehelper`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindOne
   * @summary Get an partner by id
   * @request GET:/partner/{id}
   * @response `200` `ThgPartnerViewModelGen` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerFindOne = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/${id}`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerUpdate
   * @summary Update a partner by id
   * @request PATCH:/partner/{id}
   * @secure
   * @response `200` `ThgPartnerViewModelGen` The updated record
   * @response `403` `void` Forbidden.
   */
  partnerControllerUpdate = (id: string, data: ThgUpdatePartnerDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerRemove
   * @summary Delete a partner by id
   * @request DELETE:/partner/{id}
   * @secure
   * @response `200` `ThgPartnerViewModelGen` The deleted record
   * @response `403` `void` Forbidden.
   */
  partnerControllerRemove = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerFindByName
   * @summary Deprecated: Get a partner by name
   * @request GET:/partner/{partnerName}/byName
   * @deprecated
   * @response `200` `ThgPartnerViewModelGen` The found record
   * @response `403` `void` Forbidden.
   */
  partnerControllerFindByName = (partnerName: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/${partnerName}/byName`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner
   * @name PartnerControllerUpdatePartnerCompanyUserName
   * @summary Update partners companyUsername
   * @request PATCH:/partner/{id}/username
   * @secure
   * @response `200` `ThgPartnerViewModelGen` The record has been successfully updated
   * @response `403` `void` Forbidden.
   */
  partnerControllerUpdatePartnerCompanyUserName = (
    id: string,
    data: ThgUpdatePartnerCompanyUserNameGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPartnerViewModelGen, void>({
      path: `/partner/${id}/username`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
