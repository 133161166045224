import { HttpClient } from "../v1/http-client";
import { AbstractHttpClientProvider, SignHttpClientProvider } from "./../sign-http-client.provider";
import {
  SignCreateDocumentDtoGen,
  SignDocumentControllerFindAllParamsGen,
  SignUpdateDocumentDtoGen,
  SignUpdateDocumentTokenDtoGen
} from "./../v1/data-contracts";
import { Document } from "./../v1/Document";

/**
 * Service to communicate with backend.
 */
export class DocumentService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  proxy: Document;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: AbstractHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Document(this.client);
  }

  /**
   * Sets a new proxy based on the client
   *
   * @param clientProvider the client provider
   */
  setProxy(clientProvider: AbstractHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Document(this.client);
  }

  /**
   * Creates a document.
   *
   * @param data the document dto
   */
  async create(partnerId: string, data: SignCreateDocumentDtoGen) {
    return this.proxy.documentControllerCreate(partnerId, data).then(value => value.data);
  }

  /**
   * Gets documents by partner.
   *
   * @returns the documents of the partner
   */
  async getAllForPartner(query: SignDocumentControllerFindAllParamsGen) {
    return this.proxy.documentControllerFindAll(query).then(value => value.data);
  }

  /**
   * Gets one document of the partner.
   *
   * @param id the document id
   * @returns one document of the partner
   */
  async getOneForPartner(partnerId: string, id: string) {
    return this.proxy.documentControllerFindOne(partnerId, id).then(value => value.data);
  }

  /**c
   * Removes a document
   *
   * @param partnerId of the partner
   * @param id the document id
   * @returns removed document
   */
  async removeForPartner(partnerId: string, id: string) {
    return this.proxy.documentControllerRemove(partnerId, id).then(value => value.data);
  }

  /**
   * Updates a document
   *
   * @param partnerId of the partner
   * @param id of the document
   * @returns updated document of the partner
   */
  async updateForPartner(partnerId: string, id: string, dto: SignUpdateDocumentDtoGen) {
    return this.proxy.documentControllerUpdate(partnerId, id, dto).then(value => value.data);
  }

  /**
   * Updates the tokens in a document
   *
   * @param partnerId of the partner
   * @param id of the document
   * @returns updated document of the partner
   */
  async updateDocumentTokens(partnerId: string, id: string, data: SignUpdateDocumentTokenDtoGen) {
    return this.proxy.documentControllerAddDocumentTokens(partnerId, id, data).then(value => value.data);
  }
}

/**
 * Service to communicate with backend.
 */
export default new DocumentService(new SignHttpClientProvider());
