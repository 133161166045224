













import HistoryLog from "@/lib/historyLog";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TopToolbarBack extends Vue {
  @Prop({ default: "" })
  title!: string;

  @Prop({ default: false })
  isGoHomeOnBack!: boolean;

  @Prop({ default: true })
  renderNavigationButton!: boolean;

  @Prop()
  onBack?: Function;

  async goBack() {
    // execute callback if available
    if (this.onBack) {
      this.onBack();

      return;
    }

    // go home if flag is set
    if (this.isGoHomeOnBack) {
      this.$router.push("/");

      return;
    }

    const previousRoute = HistoryLog.previous;
    const isPreviousLoginRoute = previousRoute?.startsWith("/login");
    // if user has a history with the app we go back. unless the last page was a login page.
    if (previousRoute && !isPreviousLoginRoute) {
      this.$router.push({ path: previousRoute });

      return;
    }

    // if user has no history the app or just comes from login, we remove everything behind the last "/" and go there
    try {
      // remove everything behind the last important "/" and go there
      let path = this.$route.path;

      // in case we navigated to something like "report/:reportId/" we turn this into "report/:reportId"
      if (path.endsWith("/")) {
        path = path.slice(0, path.length - 1);
      }
      const pathBits = path.split("/");
      pathBits.pop();
      const shortenedPath = pathBits.join("/") || "/";

      await this.$router.push({ path: shortenedPath });
    } catch (e) {
      // go to home screen if the naive approach to cut the last bit of the path fails. e.g. "Error: NavigationDuplicated: Avoided redundant navigation to current location"
      await this.$router.push({ path: "/" });
    }
  }
}
