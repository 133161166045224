import { IChartStringItemListViewmodel } from "@/lib/interfaces/thg/ChartStringItemListViewmodel";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import analyticsService from "@/services/thg/services/analyticsService";
import {
  ThgBaseChartItemListViewmodelGen,
  ThgBaseTrendAnalysisViewmodelGen,
  ThgCustomerDataViewModelGen,
  ThgThgImpactTypeAnalyticsViewmodelGen,
  ThgThgPartnerAnalysisViewmodelGen,
  ThgThgRealtimeAnalysisViewmodelGen,
  ThgThgStatusDistributionAnalyticsViewmodelGen,
  ThgThgUserStatsViewmodelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "thganalytics",
  store
})
export class ThgAnalyticsStore extends VuexModule {
  /**
   * Select Data from date
   */
  private _dateRange: string[] = [
    new Date().toISOString(),
    new Date(new Date().setDate(new Date().getDate() - 29)).toISOString()
  ];

  private _countryCodeYearFilter: IPageFilterElement[] = [];

  private _yearFilter: IPageFilterElement[] = [];

  get dateRange(): string[] {
    return this._dateRange;
  }

  get countryCodeYearFilter() {
    return this._countryCodeYearFilter;
  }

  get yearFilter() {
    return this._yearFilter;
  }

  get from(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return tempo;
  }

  get to(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(20, 59, 59, 999)).toISOString();
    return tempo;
  }

  private _availableCountries: string[] = [];

  get availableCountries() {
    return this._availableCountries;
  }

  private _availableYears: number[] = [];

  get availableYears() {
    return this._availableYears;
  }

  /**
   * Realtimedata of the last 12 hours of user registrations
   */
  private _userRealtimeData: ThgThgRealtimeAnalysisViewmodelGen = { data: { sum: 0, currentTrend: [] } };

  get userRealtimeData(): ThgThgRealtimeAnalysisViewmodelGen {
    return this._userRealtimeData;
  }

  /**
   * THg per User Stats
   */
  private _thgPerUserStats: ThgThgUserStatsViewmodelGen = {
    data: [],
    chartData: []
  };

  get thgPerUserStats(): ThgThgUserStatsViewmodelGen {
    return this._thgPerUserStats;
  }

  /**
   * Trendanalysis Data of meterreadings registrations
   */
  private _meterReadingTrendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  get meterReadingTrendAnalysisData(): ThgBaseTrendAnalysisViewmodelGen {
    return this._meterReadingTrendAnalysisData;
  }

  /**
   * Trendanalysis Data of promotions stored in thg
   */
  private _thgPromotionTrendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  get thgPromotionTrendAnalysisData(): ThgBaseTrendAnalysisViewmodelGen {
    return this._thgPromotionTrendAnalysisData;
  }

  /**
   * Count of promotions across thgs
   */
  private _thgPromotionCountAnalysisData: ThgBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  get thgPromotionCountAnalysisData() {
    return this._thgPromotionCountAnalysisData;
  }

  /**
   * Trendanalysis Data of user registrations
   */
  private _userTrendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  get userTrendAnalysisData(): ThgBaseTrendAnalysisViewmodelGen {
    return this._userTrendAnalysisData;
  }

  private _userMarketingOptinDistribution: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get userMarketingOptinDistribution(): ThgBaseChartItemListViewmodelGen {
    return this._userMarketingOptinDistribution;
  }

  private _userIsCompanyDistribution: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get userIsCompanyDistribution(): ThgBaseChartItemListViewmodelGen {
    return this._userIsCompanyDistribution;
  }

  /**
   * Realtimedata of the last 12 hours
   */
  private _realtimeData: ThgThgRealtimeAnalysisViewmodelGen = { data: { sum: 0, currentTrend: [] } };

  get realtimeData(): ThgThgRealtimeAnalysisViewmodelGen {
    return this._realtimeData;
  }

  /**
   * Trendanalysis Data
   */
  private _trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  get trendAnalysisData(): ThgBaseTrendAnalysisViewmodelGen {
    return this._trendAnalysisData;
  }

  private _thgCustomerData: ThgCustomerDataViewModelGen = {
    data: [],
    thg: [],
    chargingStation: [],
    meterReading: []
  };

  get thgCustomerData(): ThgCustomerDataViewModelGen {
    return this._thgCustomerData;
  }

  private _partnerDistributionData: ThgThgPartnerAnalysisViewmodelGen = {
    data: {
      partnerDistribution: [],
      partnerTypeDistribution: []
    }
  };

  get partnerDistributionData(): ThgThgPartnerAnalysisViewmodelGen {
    return this._partnerDistributionData;
  }

  private _affiliateDistributionData: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get affiliateDistributionData(): ThgBaseChartItemListViewmodelGen {
    return this._affiliateDistributionData;
  }

  private _impactTypeDistributionData: ThgThgImpactTypeAnalyticsViewmodelGen = {
    data: []
  };

  get impactTypeDistributionData(): ThgThgImpactTypeAnalyticsViewmodelGen {
    return this._impactTypeDistributionData;
  }

  private _statusDistributionData: ThgThgStatusDistributionAnalyticsViewmodelGen = {
    data: []
  };

  private _statusDistributionByPartnerData: ThgThgStatusDistributionAnalyticsViewmodelGen = {
    data: []
  };

  get statusDistributionData(): ThgThgStatusDistributionAnalyticsViewmodelGen {
    return this._statusDistributionData;
  }

  get statusDistributionByPartnerData(): ThgThgStatusDistributionAnalyticsViewmodelGen {
    return this._statusDistributionByPartnerData;
  }

  get openWorkItemsCount(): number {
    const workItems = this._statusDistributionData.data.filter(v =>
      ["created", "user_added_registration"].includes(v.name)
    );
    const count = workItems.reduce(function(acc, obj) {
      return acc + obj.value;
    }, 0);
    return count;
  }

  private _vehicleClassDistributionData: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get vehicleClassDistributionData(): ThgBaseChartItemListViewmodelGen {
    return this._vehicleClassDistributionData;
  }

  private _weekdayDistributionData: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get weekdayDistributionData(): ThgBaseChartItemListViewmodelGen {
    return this._weekdayDistributionData;
  }

  private _hourDistributionData: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get hourDistributionData(): ThgBaseChartItemListViewmodelGen {
    return this._hourDistributionData;
  }

  private _vinAnalysisData: IChartStringItemListViewmodel = {
    data: []
  };

  get vinAnalysisData(): IChartStringItemListViewmodel {
    return this._vinAnalysisData;
  }

  private _yearAnalysisData: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get yearAnalysisData(): ThgBaseChartItemListViewmodelGen {
    return this._yearAnalysisData;
  }

  private _isMultiYearAnalysisData: ThgBaseChartItemListViewmodelGen = {
    data: []
  };

  get isMultiYearAnalysisData(): ThgBaseChartItemListViewmodelGen {
    return this._isMultiYearAnalysisData;
  }

  @Mutation
  _mutateYearAnalysisData(data: ThgBaseChartItemListViewmodelGen) {
    this._yearAnalysisData = data;
  }

  @Action
  async getYearAnalysisData() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getYearDistributionData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateYearAnalysisData", data);
  }

  @Mutation
  _mutateIsMultiYearAnalysisData(data: ThgBaseChartItemListViewmodelGen) {
    this._isMultiYearAnalysisData = data;
  }

  @Action
  async getIsMultiYearAnalysisData() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getIsMultiYearDistributionData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateIsMultiYearAnalysisData", data);
  }

  @Mutation
  _mutateHourDistributionData(data: ThgBaseChartItemListViewmodelGen) {
    this._hourDistributionData = data;
  }

  @Action
  async getHourDistributionData() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getHourAnalysis(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateHourDistributionData", data);
  }

  @Mutation
  _mutateWeekdayDistributionData(data: ThgBaseChartItemListViewmodelGen) {
    this._weekdayDistributionData = data;
  }

  @Action
  async getWeekdayDistributionData() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getWeekdayAnalysis(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateWeekdayDistributionData", data);
  }

  @Mutation
  _mutateThgPerUserStats(data: ThgThgUserStatsViewmodelGen) {
    this._thgPerUserStats = data;
  }

  @Action
  async getThgPerUserStats() {
    const data: ThgThgUserStatsViewmodelGen = await analyticsService.getThgPerUserDistribution(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateThgPerUserStats", data);
  }

  @Mutation
  _mutateRealtimeData(realTimeData: ThgThgRealtimeAnalysisViewmodelGen) {
    this._realtimeData = realTimeData;
  }

  @Action
  async getRealTimeData() {
    const realTimeData: ThgThgRealtimeAnalysisViewmodelGen = await analyticsService.getRealtimeData();
    this.context.commit("_mutateRealtimeData", realTimeData);
  }

  @Mutation
  _mutateUserRealtimeData(realTimeData: ThgThgRealtimeAnalysisViewmodelGen) {
    this._userRealtimeData = realTimeData;
  }

  @Action
  async getUserRealTimeData() {
    const realTimeData: ThgThgRealtimeAnalysisViewmodelGen = await analyticsService.getUserRealTimedata();
    this.context.commit("_mutateUserRealtimeData", realTimeData);
  }

  @Mutation
  _mutateTrendanalysisData(trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen) {
    this._trendAnalysisData = trendAnalysisData;
  }

  @Action
  async getTrendAnylsisData() {
    const trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = await analyticsService.getTrendanalysisData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateTrendanalysisData", trendAnalysisData);
  }

  @Mutation
  _mutateUserTrendanalysisData(trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen) {
    this._userTrendAnalysisData = trendAnalysisData;
  }

  @Action
  async getUserTrendAnylsisData() {
    const trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = await analyticsService.getUserTrendanalysisData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateUserTrendanalysisData", trendAnalysisData);
  }

  @Mutation
  _mutateMeterReadingTrendanalysisData(trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen) {
    this._meterReadingTrendAnalysisData = trendAnalysisData;
  }

  @Action
  async getMeterReadingTrendAnylsisData() {
    const trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = await analyticsService.getMeaterReadingTrendanalysisData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateMeterReadingTrendanalysisData", trendAnalysisData);
  }

  @Mutation
  _mutateThgPromotionTrendanalysisData(trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen) {
    this._thgPromotionTrendAnalysisData = trendAnalysisData;
  }

  @Action
  async getThgPromotionTrendAnalysisData() {
    const trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = await analyticsService.getThgPromotionTrendanalysisData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateThgPromotionTrendanalysisData", trendAnalysisData);
  }

  @Mutation
  _mutateThgPromotionCountAnalysisData(data: ThgBaseTrendAnalysisViewmodelGen) {
    this._thgPromotionCountAnalysisData = data;
  }

  @Action
  async getThgPromotionCountAnalysisData() {
    const count = await analyticsService.getThgPromotionCountAnalysisData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );

    this.context.commit("_mutateThgPromotionCountAnalysisData", count);
  }

  @Mutation
  _mutatePartnerDistributionData(data: ThgThgPartnerAnalysisViewmodelGen) {
    this._partnerDistributionData = data;
  }

  @Action
  async getPartnerDistributionData() {
    const data: ThgThgPartnerAnalysisViewmodelGen = await analyticsService.getPartnerDistributionData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutatePartnerDistributionData", data);
  }

  @Mutation
  _mutateAffiliateDistributionData(data: ThgBaseChartItemListViewmodelGen) {
    this._affiliateDistributionData = data;
  }

  @Action
  async getAffiliateDistributionData() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getAffiliateDistributionData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateAffiliateDistributionData", data);
  }

  @Mutation
  _mutateUserMarketingDistribution(data: ThgBaseChartItemListViewmodelGen) {
    this._userMarketingOptinDistribution = data;
  }

  @Action
  async getUserMarketingDistribution() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getUserMarketingOptinDistribution(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateUserMarketingDistribution", data);
  }

  @Mutation
  _mutateUserIsCompanyDistribution(data: ThgBaseChartItemListViewmodelGen) {
    this._userIsCompanyDistribution = data;
  }

  @Action
  async getUserAnalysisCompanyDistribution() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getUserAnalysisCompanyDistribution(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateUserIsCompanyDistribution", data);
  }

  @Mutation
  _mutateImpactTypeDistributionData(data: ThgThgImpactTypeAnalyticsViewmodelGen) {
    this._impactTypeDistributionData = data;
  }

  @Action
  async getImpactTypeDistributionData() {
    const data: ThgThgImpactTypeAnalyticsViewmodelGen = await analyticsService.getImpactTypeDistribution(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateImpactTypeDistributionData", data);
  }

  @Mutation
  _mutateStatusDistributionData(data: ThgThgStatusDistributionAnalyticsViewmodelGen) {
    this._statusDistributionData = data;
  }

  @Mutation
  _mutateStatusDistributionByPartnerData(data: ThgThgStatusDistributionAnalyticsViewmodelGen) {
    this._statusDistributionByPartnerData = data;
  }

  @Action
  async getStatusDistributionData() {
    const data: ThgThgStatusDistributionAnalyticsViewmodelGen = await analyticsService.getStatusDistribution(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateStatusDistributionData", data);
  }

  @Mutation
  _mutateVehicleClassDistributionData(data: ThgBaseChartItemListViewmodelGen) {
    this._vehicleClassDistributionData = data;
  }

  @Mutation
  _mutateVinAnalysisData(data: ThgBaseChartItemListViewmodelGen) {
    // aggregate years with same vin
    const map: Map<string, number[]> = new Map();
    for (const entry of data.data) {
      const vin = entry.name;
      const years = map.get(vin) || [];
      map.set(vin, [...years, entry.value]);
    }

    // transform map to IChartStringItemListViewmodel, where years are an ascending list
    const result = [];
    for (const key of map.keys()) {
      result.push({ name: key, value: (map.get(key) || []).sort((a, b) => a - b).join(", ") });
    }

    // remove duplicates
    this._vinAnalysisData = { data: result };
  }

  @Action
  async getVehicleClassDistributionData() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getVehicleClassDistributionData(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateVehicleClassDistributionData", data);
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  @Mutation
  _mutateCountryCodeYearFilter(filter: IPageFilterElement[]) {
    this._countryCodeYearFilter = filter;
  }

  @Action
  setCountryCodeYearFilter(filter: IPageFilterElement[]) {
    this.context.commit("_mutateCountryCodeYearFilter", filter);
  }

  @Mutation
  _mutateYearFilter(filter: IPageFilterElement[]) {
    this._yearFilter = filter;
  }

  @Action
  setYearFilter(filter: IPageFilterElement[]) {
    this.context.commit("_mutateYearFilter", filter);
  }

  @Action
  async getAvailableCountries() {
    const availableCountries = await analyticsService.getAvailableCountries();
    this.context.commit("_mutateAvailableCountries", availableCountries);
  }

  @Mutation
  _mutateAvailableCountries(countries: string[]) {
    this._availableCountries = countries;
  }

  @Action
  async getAvailableYears() {
    const availableYears = await analyticsService.getAvailableYears();
    this.context.commit("_mutateAvailableYears", availableYears);
  }

  @Mutation
  _mutateAvailableYears(years: number[]) {
    this._availableYears = years;
  }

  /**
   * Specific data reqeust for partners
   */

  @Action
  async getRealTimeDataPerPartner() {
    const realTimeData: ThgThgRealtimeAnalysisViewmodelGen = await analyticsService.getRealtimeDataPerPartner();
    this.context.commit("_mutateRealtimeData", realTimeData);
  }

  @Action
  async getStatusDistributionDataPerPartner() {
    const data: ThgThgStatusDistributionAnalyticsViewmodelGen = await analyticsService.getStatusDistributionPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateStatusDistributionByPartnerData", data);
  }

  @Action
  async getImpactTypeDistributionDataPerPartner() {
    const data: ThgThgImpactTypeAnalyticsViewmodelGen = await analyticsService.getImpactTypeDistributionPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateImpactTypeDistributionData", data);
  }

  @Action
  async getVehicleClassDistributionDataPerPartner() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getVehicleClassDistributionDataPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateVehicleClassDistributionData", data);
  }

  @Action
  async getAffiliateDistributionDataPerPartner() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getAffiliateDistributionDataPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateAffiliateDistributionData", data);
  }

  @Action
  async getTrendAnylsisDataPerPartner() {
    const trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = await analyticsService.getTrendanalysisDataPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateTrendanalysisData", trendAnalysisData);
  }

  @Action
  async getMeterReadingTrendAnylsisDataPerPartner() {
    const trendAnalysisData: ThgBaseTrendAnalysisViewmodelGen = await analyticsService.getMeterReadingTrendanalysisDataPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateMeterReadingTrendanalysisData", trendAnalysisData);
  }

  @Action
  async getWeekdayDistributionDataPerPartner() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getWeekdayAnalysisPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateWeekdayDistributionData", data);
  }

  @Action
  async gethourDistributionDataPerPartner() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getHourAnalysisPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateHourDistributionData", data);
  }

  @Action
  async getVinAnalysisDataPerPartner() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getVinAnalysisPerPArtner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateVinAnalysisData", data);
  }

  @Action
  async getYearAnalysisDataPerPartner() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getYearDistributionDataPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateYearAnalysisData", data);
  }

  @Action
  async getIsMultiYearAnalysisDataPerPartner() {
    const data: ThgBaseChartItemListViewmodelGen = await analyticsService.getIsMultiYearDistributionDataaPerPartner(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateIsMultiYearAnalysisData", data);
  }

  @Action
  async getThgCustomerDataPerPartner() {
    const data: ThgCustomerDataViewModelGen = await analyticsService.getCustomerDataByPartnerId(
      this.from,
      this.to,
      this.countryCodeYearFilter
    );
    this.context.commit("_mutateThgCustomerDataPerPartner", data);
  }

  @Mutation
  _mutateThgCustomerDataPerPartner(data: ThgCustomerDataViewModelGen) {
    this._thgCustomerData = data;
  }
}

export const ThgAnalyticsModule = getModule(ThgAnalyticsStore);
