import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IShortUser, ShortUser } from "@/models/short-user.entity";
import { MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import PartnerUserService from "@/services/shared/partnerUserService";
import { Action, Module, getModule } from "vuex-module-decorators";
import store from "../VuexPlugin";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PartnerUserAccessLayer } from "./access-layers/partner-user.access-layer";
import { PartnerModule } from "./partner";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import Vue from "vue";

const PartnerUserPageDataProvider = new (class extends AbstractPageDataProvider<
  IShortUser,
  MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen): Promise<IPageViewModel<IShortUser>> {
    const res = await PartnerUserService.findAll(query);

    return res as IPageViewModel<IShortUser>;
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "partnerUser",
  store
})
export class PartnerUserStore extends PaginatedBaseStore<
  IShortUser,
  MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen
> {
  protected _data = PartnerUserAccessLayer;
  protected _pageProvider = PartnerUserPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions = ShortUser.filterables;

  /**
   * Clears store
   * if user is permitted also repopulates store
   */
  @Action
  async reset(): Promise<void> {
    super.reset();

    const partnerId = PartnerModule.partner.id;
    if (partnerId && UserModule.abilities.can(ActionEnum.READ, ResourceEnum.USER, partnerId)) {
      this.setFilters([]);
      await this.fetchAll({ partnerId }).catch(e => Vue.$log.error(e));
    }
  }
}

export const PartnerUserModule = getModule(PartnerUserStore);
