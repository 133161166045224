/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateVehicleDocumentDtoGen,
  MrfiktivCreateVehicleDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateVehicleDtoGen,
  MrfiktivUpdateVehicleRegistrationDtoGen,
  MrfiktivUpdateVehicleStateDtoGen,
  MrfiktivVehicleControllerCreateBatchPayloadGen,
  MrfiktivVehicleControllerCreateContractPayloadGen,
  MrfiktivVehicleControllerGetAllParamsGen,
  MrfiktivVehicleControllerUpdateContractPayloadGen,
  MrfiktivVehicleViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Vehicle<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerSyncIndex
   * @summary Syncs indexes of vehicle
   * @request POST:/syncindexes
   * @response `200` `void` Sync result
   * @response `400` `void` Bad request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerSyncIndex = (params: RequestParams = {}) =>
    this.http.request<void, void | MrfiktivExceptionViewmodelGen>({
      path: `/syncindexes`,
      method: "POST",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerCreate
   * @summary (VEHICLE-CREATE) Creates a vehicle
   * @request POST:/partner/{partnerId}/vehicle
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` the created vehicle
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerCreate = (partnerId: string, data: MrfiktivCreateVehicleDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerGetAll
   * @summary (VEHICLE-READ) Get all vehicles
   * @request GET:/partner/{partnerId}/vehicle
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivVehicleViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerGetAll = (
    { partnerId, ...query }: MrfiktivVehicleControllerGetAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivVehicleViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/vehicle`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerCreateBatch
   * @summary (VEHICLE-CREATE) Creates a vehicle
   * @request POST:/partner/{partnerId}/vehicle/batch
   * @secure
   * @response `201` `(MrfiktivVehicleViewModelGen)[]` (VEHICLE - CREATE) the created vehicles
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerCreateBatch = (
    partnerId: string,
    data: MrfiktivVehicleControllerCreateBatchPayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen[], MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/batch`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerGetOne
   * @summary (VEHICLE-READ) Get a vehicle by vehicleId
   * @request GET:/partner/{partnerId}/vehicle/{vehicleId}
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` Get a vehicle by vehicleId
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerGetOne = (partnerId: string, vehicleId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerUpdate
   * @summary (VEHICLE-UPDATE) Update a vehicle
   * @request PUT:/partner/{partnerId}/vehicle/{vehicleId}
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` The updated vehicle.
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerUpdate = (
    partnerId: string,
    vehicleId: string,
    data: MrfiktivUpdateVehicleDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerDelete
   * @summary (VEHICLE-DELETE) Delete a vehicle and its events
   * @request DELETE:/partner/{partnerId}/vehicle/{vehicleId}
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` The deleted record
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerDelete = (partnerId: string, vehicleId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerUpdateVehicleState
   * @summary (VEHICLE-UPDATE) Update the state of a vehicle
   * @request PATCH:/partner/{partnerId}/vehicle/{vehicleId}/state
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` Vehicle with updated state
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerUpdateVehicleState = (
    partnerId: string,
    vehicleId: string,
    data: MrfiktivUpdateVehicleStateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/state`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerUpdateRegistration
   * @summary (VEHICLE-UPDATE) Update a vehicle registration
   * @request PUT:/partner/{partnerId}/vehicle/{vehicleId}/registration
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` Id of created operation
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerUpdateRegistration = (
    partnerId: string,
    vehicleId: string,
    data: MrfiktivUpdateVehicleRegistrationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/registration`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerCreateDocument
   * @summary (VEHICLE-CREATE) Creates a document for a vehicle
   * @request POST:/partner/{partnerId}/vehicle/{vehicleId}/document
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` the created vehicle
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerCreateDocument = (
    partnerId: string,
    vehicleId: string,
    data: MrfiktivCreateVehicleDocumentDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/document`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerUpdateDocument
   * @summary (VEHICLE-UPDATE) Updates a document for a vehicle
   * @request PUT:/partner/{partnerId}/vehicle/{vehicleId}/document/{documentId}
   * @secure
   * @response `200` `MrfiktivVehicleViewModelGen` the created vehicle
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerUpdateDocument = (
    partnerId: string,
    vehicleId: string,
    documentId: string,
    data: MrfiktivCreateVehicleDocumentDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/document/${documentId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerDeleteDocument
   * @summary (VEHICLE-DELETE) Deletes a document of a vehicle
   * @request DELETE:/partner/{partnerId}/vehicle/{vehicleId}/document/{documentId}
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` the vehicle with the deleted document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerDeleteDocument = (
    partnerId: string,
    vehicleId: string,
    documentId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/document/${documentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerCreateContract
   * @summary (VEHICLE-CREATE) Creates a vehicle contract
   * @request POST:/partner/{partnerId}/vehicle/{vehicleId}/contract
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` the created vehicle
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerCreateContract = (
    partnerId: string,
    vehicleId: string,
    data: MrfiktivVehicleControllerCreateContractPayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/contract`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerUpdateContract
   * @summary (VEHICLE-Update) Update a vehicle contract
   * @request PUT:/partner/{partnerId}/vehicle/{vehicleId}/contract/{contractId}
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` the updated vehicle
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerUpdateContract = (
    partnerId: string,
    vehicleId: string,
    contractId: string,
    data: MrfiktivVehicleControllerUpdateContractPayloadGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/contract/${contractId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags vehicle
   * @name VehicleControllerDeleteContract
   * @summary (VEHICLE-Update) Update a vehicle contract
   * @request DELETE:/partner/{partnerId}/vehicle/{vehicleId}/contract/{contractId}
   * @secure
   * @response `201` `MrfiktivVehicleViewModelGen` the updated vehicle
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  vehicleControllerDeleteContract = (
    partnerId: string,
    vehicleId: string,
    contractId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivVehicleViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/contract/${contractId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
