/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import { PartnerUser as MrfiktivPartnerUser } from "../mrfiktiv/v1/PartnerUser";
import {
  MrfiktivCreateUserDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen,
  MrfiktivShortUserViewModelGen,
  MrfiktivUserIdentityViewModelGen,
  MrfiktivUserViewmodelGen
} from "../mrfiktiv/v1/data-contracts";

class PartnerUserService {
  /**
   *
   */
  mrfiktivPartnerUser: MrfiktivPartnerUser;

  /**
   *
   * @param mrfiktivHttpClientProvider
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivPartnerUser = new MrfiktivPartnerUser(mrfiktivHttpClientProvider.client());
    // TODO: add thg
  }

  async create(partnerId: string, data: MrfiktivCreateUserDtoGen): Promise<MrfiktivUserViewmodelGen> {
    return (await this.mrfiktivPartnerUser.partnerUserControllerCreate(partnerId, data)).data;
  }

  async remove(partnerId: string, userId: string): Promise<MrfiktivUserViewmodelGen> {
    return (await this.mrfiktivPartnerUser.partnerUserControllerRemove(partnerId, userId)).data;
  }

  async findOne(partnerId: string, userId: string): Promise<MrfiktivUserViewmodelGen> {
    return (await this.mrfiktivPartnerUser.partnerUserControllerFindOneByPartnerId(partnerId, userId)).data;
  }

  async findOneByName(partnerId: string, username: string): Promise<MrfiktivUserViewmodelGen> {
    return (await this.mrfiktivPartnerUser.partnerUserControllerFindOneByNameAndPartnerId(partnerId, username)).data;
  }

  async getStatus(partnerId: string, username: string): Promise<MrfiktivUserIdentityViewModelGen> {
    return (await this.mrfiktivPartnerUser.partnerUserControllerGetUserStatusByPartnerId(partnerId, username)).data;
  }

  async findAll(
    query: MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen
  ): Promise<MrfiktivPageViewModelGen & { data?: MrfiktivShortUserViewModelGen[] }> {
    return (await this.mrfiktivPartnerUser.partnerUserControllerFindAllByPartnerId(query)).data;
  }
}

export default new PartnerUserService(new MrfiktivHttpClientProvider());
