










































import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class TheSettingsList extends Vue {
  set debug(bo: boolean) {
    ConfigModule.setDebug(bo);
  }

  get debug() {
    return ConfigModule.debug;
  }

  toggle() {
    this.darkMode = !this.darkMode;
  }

  sendMail() {
    const mailText = `mailto:${ConfigModule.supportMail}`;

    window.open(mailText, "_blank");
  }

  changeLanguage() {
    // make this in the future more generic, depending on our supported languages
    if (this.$i18n.locale === "de") {
      this.$i18n.locale = "en";
    } else {
      this.$i18n.locale = "de";
    }
  }

  set darkMode(bo: boolean) {
    ConfigModule.setDarkMode(bo);
  }

  get darkMode() {
    return ConfigModule.darkMode;
  }

  get isMintFuture(): boolean {
    return PartnerModule?.partner?.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE;
  }

  submitRating() {
    window.open(ConfigModule.googleThgRatingLink, "_blank");
  }
}
