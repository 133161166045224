export enum ReportImageType {
  "damage" = "damage",
  "damageDetail" = "damageDetail",
  "plate" = "plate",
  "cockpit" = "cockpit",
  "overview" = "overview",
  "registration" = "registration",
  "registrationBack" = "registrationBack",
  "others" = "others",
  "vin" = "vin",
  "tuev" = "tuev",
  "tire" = "tire",
  "driverslicensefront" = "driverslicensefront",
  "driverslicenseback" = "driverslicenseback",
  "scene" = "scene",

  /**
   * Old damage, that are already submitted
   */
  preExisting = "preExisting"
}
