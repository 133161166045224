var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('top-toolbar-back', {
    attrs: {
      "title": _vm.title,
      "isGoHomeOnBack": _vm.isGoHomeOnBack,
      "renderNavigationButton": _vm.renderNavigationButton,
      "onBack": _vm.onBack
    }
  }), _c('v-main', [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }