var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "justify-center align-center "
  }, [_c('v-row', [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": _vm.src,
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v("Keine Einträge")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }