













import { Component, Vue } from "vue-property-decorator";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";

@Component({})
export default class LatestEntriesCardEmpty extends Vue {
  get src() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }
}
