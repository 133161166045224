import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ImageResizerOutputypeEnum } from "@/lib/enum/imageReziserOutputype.enum";
import { IImageResizeOptions } from "@/lib/interfaces/image-resize-options.interface";
import { IAppColorConfig } from "@/lib/utility/AppColorSchema";
import Vuetify from "@/plugins/VuetifyPlugin";
import VuetifyThg from "@/plugins/VuetifyPluginThg";
import store from "@/store/VuexPlugin";
import { SessionModule } from "@/store/modules/session";
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import { version } from "../../../package.json";
import { INavigationSettings } from "../interface/navigation-settings.interface";

@Module({
  dynamic: true,
  namespaced: true,
  name: "config",
  store
})
export class Config extends VuexModule {
  LOCAL_STORAGE_ACCESS_TOKEN = "access_token";
  LOCAL_DARK_MODE = "darkMode";

  debug = false;
  darkMode: boolean = JSON.parse(localStorage.getItem(this.LOCAL_DARK_MODE) || "false");

  token = "";
  fahrzeugscheinToken = "";

  isLoginSheetVisible = false;
  isFahrzeugscheinTokenValid = false;

  BASE_URL_PORT = 3000;
  fahrzeugscheinBaseUrl = "https://api.mmmint.ai/fahrzeugschein/v1";

  privacyPolicyLink = "https://www.mmmint.ai/datenschutz/";

  throttleTimerInMs = 2000;

  developmentStatusAcknowledgement = false;

  docphantUrls = ["https://dev.app.docphant.com/#", "https://app.docphant.com/#"];

  // Backend URL
  useLocalHost = process.env.VUE_APP_LOCALHOST === "true";
  useLocalHostMrfiktiv = process.env.VUE_APP_LOCALHOST_MRFIKTIV === "true";
  useLocalHostSign = process.env.VUE_APP_LOCALHOST_SIGN === "true";
  useLocalHostThg = process.env.VUE_APP_LOCALHOST_THG === "true";
  useLocalHostBooking = process.env.VUE_APP_LOCALHOST_BOOKING === "true";
  useLocalHostImage = process.env.VUE_APP_LOCALHOST_IMAGE === "true";
  useLocalHostAinonymizer = process.env.VUE_APP_LOCALHOST_AINONYMIZER === "true";

  productionUrl = "https://api.mrfiktiv.com/v1";
  developmentUrl = "https://dev.api.mrfiktiv.com/v1";
  localUrl = `http://localhost:${this.BASE_URL_PORT}`;

  productionThgUrl = "https://api.mmmint.ai/thg/v1";
  developmentThgUrl = "https://dev.api.mmmint.ai/thg/v1";

  googleThgRatingLink = "https://g.page/r/CXTEJQalPANhEAo/review";

  productionPublicImageUrl = "https://api.mmmint.ai/image/v1";
  developmentPublicImageUrl = "https://dev.api.mmmint.ai/image/v1";

  // For development we want to use the dev key. otherwise we want to fetch it from the config endpoint.
  devAppInsightsKey = "43fa64c5-1620-4eb3-88d7-9819e4f17d47";
  prodAppInsightsKey = "05cf2f6a-8b2b-4c1b-9cab-3c67afdd0ecb";
  prodThgAppInsightsKey = "244675ed-b1f7-4da6-a496-f86fc27a1b85";
  appInsightsKey = process.env.NODE_ENV === "production" ? this.prodAppInsightsKey : this.devAppInsightsKey;
  appThgInsightsKey = process.env.NODE_ENV === "production" ? this.prodThgAppInsightsKey : this.devAppInsightsKey;

  productionPortalUrl = "https://portal.schadensmeldung.digital/#";
  developmentPortalUrl = "https://dev.portal.schadensmeldung.digital/#";
  localPortalUrl = "localhost:8080/portal#";

  portalUrl =
    process.env.NODE_ENV === "production"
      ? this.productionPortalUrl
      : this.useLocalHost || this.useLocalHostMrfiktiv
      ? this.localPortalUrl
      : this.developmentPortalUrl;

  thgBaseUrl =
    process.env.NODE_ENV === "production"
      ? this.productionThgUrl
      : this.useLocalHost || this.useLocalHostThg
      ? this.localUrl
      : this.developmentThgUrl;

  mrfiktivBaseUrl =
    process.env.NODE_ENV === "production"
      ? this.productionUrl
      : this.useLocalHost || this.useLocalHostMrfiktiv
      ? this.localUrl
      : this.developmentUrl;

  publicImageBaseUrl =
    process.env.NODE_ENV === "production"
      ? this.productionPublicImageUrl
      : this.useLocalHost || this.useLocalHostImage
      ? this.localUrl
      : this.developmentPublicImageUrl;

  bookingBaseUrl =
    process.env.NODE_ENV === "production"
      ? "https://api.mmmint.ai/booking/v1" //TODO: VERIFY URL
      : this.useLocalHost || this.useLocalHostBooking
      ? this.localUrl
      : "https://dev.api.mmmint.ai/booking/v1"; //TODO: VERIFY URL

  digitalSignatureBaseUrl =
    process.env.NODE_ENV === "production"
      ? "https://api.mmmint.ai/sign/v1"
      : this.useLocalHost || this.useLocalHostSign
      ? this.localUrl
      : "https://dev.api.mmmint.ai/sign/v1";

  digitalSignatureThgBaseUrl =
    process.env.NODE_ENV === "production"
      ? "https://api.mmmint.ai/sign-thg/v1"
      : this.useLocalHost || this.useLocalHostSign
      ? this.localUrl
      : "https://dev.api.mmmint.ai/sign/v1";

  ainonymizerBaseUrl =
    process.env.NODE_ENV === "production"
      ? "https://api.mmmint.ai/ainonymizer"
      : this.useLocalHost || this.useLocalHostAinonymizer
      ? this.localUrl
      : "https://dev.api.mmmint.ai/ainonymizer";

  mrfiktivAccessToken = localStorage.getItem(this.LOCAL_STORAGE_ACCESS_TOKEN) || "";
  localLoginUlr =
    "https://dev-mrfiktiv.auth.eu-central-1.amazoncognito.com/login?client_id=6p2pq3ae23s1s5vv483un0o0cn&response_type=token&scope=email+openid&redirect_uri=http://localhost:8080/";
  developmentLoginUrl =
    "https://dev-mrfiktiv.auth.eu-central-1.amazoncognito.com/login?client_id=6g006bg2mbgp04cr9pr805r5a1&response_type=token&scope=email+openid&redirect_uri=https://dev.app.mrfiktiv.com/";
  productionLoginUrl =
    "https://mrfiktiv.auth.eu-central-1.amazoncognito.com/login?response_type=token&client_id=47q5nfpd31i3md3cegc5qpfo74&redirect_uri=https%3A%2F%2Fapp.mrfiktiv.com%2F";

  loginUrl = process.env.NODE_ENV === "production" ? this.productionLoginUrl : this.developmentLoginUrl;

  // Image Resizing
  isResizingImagesReport = true;
  reportImageResizingOptions: IImageResizeOptions = {
    maxWidth: 2560,
    maxHeight: 2560,
    outputType: ImageResizerOutputypeEnum.JPG,
    outputQuality: 0.8
  };

  registrationImageResizingOptions: IImageResizeOptions = {
    maxWidth: 2560,
    maxHeight: 2560,
    outputType: ImageResizerOutputypeEnum.JPG,
    outputQuality: 1
  };

  defaultLogoUrl = AssetRepository.getAsset(false, AssetEnum.mmmint);

  // Defaults Colors Analytics
  mintFutureColor = {
    primary: "#7bb942",
    secondary: "#BDBDBD",
    status: {
      finished: "green darken-2",
      processing: "#ff5661",
      pending: "#FFFFFF"
    },
    analyticsColors: ["#7bb942", "#FFCA0D", "#4e4e4e", "#2A88F5", "#CFF000", "#F5F5F5", "#e4f2ee", "#FFCA0D"],
    analyticsBrandColors: ["#BEF9C3", "#63C58A", "#32A660", "#009573", "#8BD6B8", "#63B2A9", "#AAEB94"]
  };

  // Default Colors APP
  mintFutureColorSchema: IAppColorConfig = {
    themes: {
      light: {
        primary: "#7bb942",
        secondary: "#2c2f3b",
        warning: "#f7bb23",
        success: "#70cd00",
        error: "#ff4040",
        info: "#56a0da"
      },
      dark: {
        primary: "#7bb942",
        secondary: "#2c2f3b",
        warning: "#f7bb23",
        success: "#70cd00",
        error: "#ff4040",
        info: "#56a0da"
      }
    }
  };

  // Defaults Colors Analytics
  damagereportColor = {
    primary: "#4283FF",
    secondary: "#3BC8C1",
    status: {
      finished: "green darken-2",
      processing: "#ff5661",
      pending: "#FFFFFF"
    },
    analyticsColors: ["#3BC8C1", "#4283FF", "#212529", "#F0F0F0", "#8BD6B8", "#63B2A9", "#AAEB94", "#FFCA0D"],
    analyticsBrandColors: ["#3BC8C1", "#4283FF", "#212529", "#F0F0F0", "#8BD6B8", "#63B2A9", "#AAEB94"]
  };

  // Default Colors APP
  damageReportColorSchema: IAppColorConfig = {
    themes: {
      light: {
        primary: "#4283FF",
        secondary: "#3BC8C1"
      },
      dark: {
        primary: "#4283FF",
        secondary: "#3BC8C1"
      }
    }
  };

  get imageInputTypes() {
    return ["image/webp", "image/png", "image/jpeg", ".jpg", ".jpeg", ".png", ".ico"];
  }

  get imageInputTypesString() {
    return this.imageInputTypes.join(",");
  }

  get pdfInputTypes() {
    return this.imageInputTypes.concat(["application/pdf", ".pdf"]);
  }

  get pdfInputTypesString() {
    return this.pdfInputTypes.join(",");
  }

  get isProd() {
    return (
      process.env.NODE_ENV === "production" || location.hostname === "localhost" || location.hostname === "127.0.0.1"
    );
  }

  get color() {
    switch (this.appContext) {
      case AppContextEnum.THG_PORTAL:
        return this.mintFutureColor;
      case AppContextEnum.REPORT_PORTAL:
        return this.damagereportColor;
      case AppContextEnum.REPORT:
        return this.damagereportColor;
      case AppContextEnum.SIGN:
        return this.damagereportColor;
      case AppContextEnum.THG:
        return this.mintFutureColor;
      default:
        return this.mintFutureColor;
    }
  }

  /**
   * The NavigationSettings
   */
  _navigationSettings: INavigationSettings = {
    showMmmmintLogo: true,
    showTheReportList: false,
    // Show links in user-facing app
    showTheBookingList: false,
    showThePartnerList: true,
    showTheCustomerList: true,
    showTheOrganizationList: true,
    showTheFleetList: true,
    // Shows links in portal app
    showTheOnlineBookingList: true,
    showTheAdminList: true,
    showTheThgAdminList: false,
    showTheThgList: false,
    showTheAboutList: true,
    showThgAboutList: false,
    showTheThgProcessingList: false,
    showTheThgPartnerList: false,
    showThgFeatureSettings: false,
    showDocphant: false,
    showTheDigitalSignatureList: true,
    showTheProjectList: true,
    showTheCostList: true
  };

  /**
   * Logo that is displayed in Portal
   */
  _portalLogo = AssetEnum.mmmint;

  preferredAdminThgPartner =
    process.env.NODE_ENV === "production" ? "61ffe0712754ad3b32508d26" : "61e9880bb4c41b6269ef5649";

  portalLogo() {
    return this._portalLogo;
  }

  get appVersion() {
    return version || "1.0.0";
  }

  get navigationSettings(): INavigationSettings {
    return this._navigationSettings;
  }

  /**
   * Which app is running
   */
  _appContext: AppContextEnum = AppContextEnum.REPORT;

  @Mutation
  _mutateAppContext(context: AppContextEnum) {
    this._appContext = context;
  }

  @Action
  setAppContext(context: AppContextEnum) {
    this.context.commit("_mutateAppContext", context);
  }

  /**
   * Defines if Bankinginformations are fetched
   */
  _isBankingAvailable = false;

  get isBankingAvailable() {
    return this._isBankingAvailable;
  }

  /**
   * Defines if PartnerBanking is available
   */
  _isPartnerBankingAvailable = false;

  get isPartnerBankingAvailable() {
    return this._isPartnerBankingAvailable;
  }

  /**
   * Assume the camera is available.
   */
  _isCameraAvailable = true;

  /**
   * After selecting an PArtner over the partnerselector the redirect is to the following page
   */
  _partnerSelectorDestinationVue = "PartnerDashboard";

  get isCameraAvailable() {
    return this._isCameraAvailable;
  }

  get partnerSelectorDestinationVue() {
    return this._partnerSelectorDestinationVue;
  }

  get appContext() {
    return this._appContext;
  }

  @Action
  setPartnerSelectorDestinationVue(destinationVue: string) {
    this.context.commit("_mutatePartnerSelectorDestinationVue", destinationVue);
  }

  @Mutation
  async _mutatePartnerSelectorDestinationVue(destinationVue: string) {
    this._partnerSelectorDestinationVue = destinationVue;
  }

  @Action
  setPortalLogo(logoEnum: AssetEnum) {
    this.context.commit("_mutatePortalLogo", logoEnum);
  }

  @Mutation
  async _mutatePortalLogo(logoEnum: AssetEnum) {
    this._portalLogo = logoEnum;
  }

  @Action
  setisBankingAvailable(val: boolean) {
    this.context.commit("_mutateIsBankingAvailable", val);
  }

  @Mutation
  async _mutateIsBankingAvailable(val: boolean) {
    this._isBankingAvailable = val;
  }

  @Action
  setIsPartnerBankingAvailable(val: boolean) {
    this.context.commit("_mutateIsPartnerBankingAvailable", val);
  }

  @Mutation
  async _mutateIsPartnerBankingAvailable(val: boolean) {
    this._isPartnerBankingAvailable = val;
  }

  @Action
  setNavigationSettings(navigationSettings: Partial<INavigationSettings>) {
    this.context.commit("_mutateNavigationSettings", { ...this._navigationSettings, ...navigationSettings });
  }

  @Mutation
  _mutateNavigationSettings(navigationSettings: INavigationSettings) {
    this._navigationSettings = navigationSettings;
  }

  @Action
  setIsCameraAvailable(val: boolean) {
    this.context.commit("updateIsCameraAvailable", val);
  }

  @Mutation
  async updateIsCameraAvailable(val: boolean) {
    this._isCameraAvailable = val;
  }

  @MutationAction
  async setToken(token: string) {
    return {
      token
    };
  }

  @MutationAction
  async setDebug(debug: boolean) {
    return {
      debug
    };
  }

  @Action
  async setThgBaseUrl() {
    const baseUrl =
      process.env.NODE_ENV === "production"
        ? this.productionThgUrl
        : this.useLocalHost
        ? this.localUrl
        : this.developmentThgUrl;

    this.context.commit("setMrfiktivBaseUrl", baseUrl);
  }

  @Mutation
  async setMrfiktivAccessToken(mrfiktivAccessToken: string) {
    this.mrfiktivAccessToken = mrfiktivAccessToken;
  }

  @Mutation
  async setMrfiktivBaseUrl(mrfiktivBaseUrl: string) {
    this.mrfiktivBaseUrl = mrfiktivBaseUrl;
  }

  @Mutation
  updateDevelopmentStatusAcknowledgement(val: boolean) {
    this.developmentStatusAcknowledgement = val;
  }

  @Action
  updateMrfiktivAccessToken(val: string) {
    localStorage.setItem(this.LOCAL_STORAGE_ACCESS_TOKEN, val);
    this.context.commit("setMrfiktivAccessToken", val);
  }

  @Action
  logoutMrfiktiv() {
    localStorage.removeItem(this.LOCAL_STORAGE_ACCESS_TOKEN);
    this.context.commit("setMrfiktivAccessToken", "");
  }

  @Action
  async setDevelopmentStatusAcknowledgement(val: boolean) {
    this.context.commit("updateDevelopmentStatusAcknowledgement", val);
  }

  @Action
  async setDarkMode(val: boolean) {
    localStorage.setItem(this.LOCAL_DARK_MODE, String(val));
    this.context.commit("updateDarkModeSetting", val);
  }

  @Mutation
  updateDarkModeSetting(val: boolean) {
    Vuetify.framework.theme.dark = val;
    VuetifyThg.framework.theme.dark = val;
    this.darkMode = val;
  }

  @Action
  async setFahrzeugscheinToken(val: string) {
    this.context.commit("updateFahrzeugscheinToken", val);
    await SessionModule.fetchSessions();
  }

  @Action
  async setFahrzeugscheinTokenValid(val: boolean) {
    this.context.commit("updateFahrzeugscheinTokenValid", val);
  }

  @Action
  async setCachedFahrzeugscheine(val: boolean) {
    this.context.commit("updateFahrzeugscheinTokenValid", val);
  }

  @Mutation
  updateFahrzeugscheinToken(val: string) {
    this.fahrzeugscheinToken = val;
  }

  @Mutation
  updateFahrzeugscheinTokenValid(val: boolean) {
    this.isFahrzeugscheinTokenValid = val;
  }

  @Action
  makeLoginSheetVisible() {
    this.context.commit("setLoginSheetVisible", true);
  }

  @Action
  hideLoginSheetVisible() {
    this.context.commit("setLoginSheetVisible", false);
  }

  @Mutation
  setLoginSheetVisible(val: boolean) {
    this.isLoginSheetVisible = val;
  }

  _supportMail = "info@mmmint.ai";

  get supportMail() {
    return this._supportMail;
  }

  @Mutation
  mutateSupportMail(supportMail: string) {
    this._supportMail = supportMail;
  }

  @Action
  setThgSupportMail() {
    const thgMail = "info@mintfuture.de";
    this.context.commit("mutateSupportMail", thgMail);
  }

  /**
   * Displays the navigation elements
   */
  _headless = false;

  get headless() {
    return this._headless;
  }

  @Mutation
  mutateHeadless(value: boolean) {
    this._headless = value;
  }

  @Action
  setHeadless(data: { value: boolean }) {
    this.context.commit("mutateHeadless", data.value);
  }
}

export const ConfigModule = getModule(Config);
