import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IShortUser, ShortUser } from "@/models/short-user.entity";

export const PartnerUserAccessLayer = new (class extends AbstractLocalDataAccessLayer<IShortUser> {
  getIdentifier(entity: IShortUser): string {
    return entity.id;
  }

  protected mapEntity(entity: IShortUser): IShortUser {
    return new ShortUser(entity);
  }
})();
