import {
  MrfiktivCreateVehicleRegistrationDtoGen,
  MrfiktivVehicleRegistrationViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { Registration } from "./registration.entity";

class VehicleRegistrationBase extends Registration implements MrfiktivVehicleRegistrationViewModelGen {
  transmission?: "automatic" | "manual" | "" = "";

  constructor(registration?: MrfiktivCreateVehicleRegistrationDtoGen | MrfiktivVehicleRegistrationViewModelGen) {
    super(registration);

    this.transmission = registration?.transmission;
  }
}

type IVehicleRegistration = VehicleRegistrationBase;
const VehicleRegistration = VehicleRegistrationBase;

export { IVehicleRegistration, VehicleRegistration };
