import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { handleError } from "@/lib/utility/handleError";
import {
  MrfiktivActivityLogViewModelGen,
  MrfiktivReferenceGen,
  MrfiktivUpdateActivityLogDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import sharedActivityLogService from "@/services/shared/sharedActivityLogService";
import {
  ThgActivityLogControllerFindAllParamsGen,
  ThgActivityLogViewModelGen,
  ThgCreateActivityLogDtoGen,
  ThgReferenceGen,
  ThgUpdateActivityLogDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { ActionEnum } from "../enum/authActionEnum";
import {
  MrfiktivActivityLogControllerFindAllParamsGen,
  MrfiktivCreateActivityLogDtoGen
} from "./../../services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "./../enum/authResourceEnum";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

export enum ActivityTypeEnum {
  CREATE_DOCUMENT = "document",
  CREATE_ASSIGNEE = "addAssignee",
  CREATE_COST = "createCost",
  CREATE_IMAGE = "image",
  CREATE_TICKET = "ticket",
  DELETE_ASSIGNEE = "deleteAssignee",
  REQUEST_SIGNATURE = "sign-request",
  REQUEST_ATTACHMENT = "attachmentRequest",
  UPDATE_PROGRESS_STATUS = "progressStatusUpdate",
  UPDATE_REPORT = "reportUpdate",
  CREATE_EVENT_SERIES_FROM_OLD = "deriveNewSeriesFromOld",
  INTERRUPT_EVENT_SERIES_WITH_NEW = "interruptEventSeriesWithNew",
  SHARE = "share",
  SHARE_REPORT_INITIAL = "initialReportShare",
  MESSAGE = "message",
  COMMENT = "comment",
  CREATE = "created",
  SEND_PROGRESS_STATUS_UPDATE_MESSAGE = "progressStatusUpdateMessage"
}

export class ActivityLogBaseStore extends BasePagination<
  MrfiktivActivityLogViewModelGen | ThgActivityLogViewModelGen,
  MrfiktivActivityLogControllerFindAllParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivActivityLogViewModelGen[] | ThgActivityLogViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = [
    { key: "partnerId", type: PageFilterTypes.OBJECT_ID },
    { key: "source.refId", type: PageFilterTypes.OBJECT_ID },
    { key: "source.refType", type: PageFilterTypes.ENUM, config: { items: Object.values(BackendResourceEnum) } },
    { key: "actionType", type: PageFilterTypes.STRING, config: { items: Object.values(ActionEnum) } },
    { key: "activity", type: PageFilterTypes.STRING }
  ].map(f => new PaginationFilterListElement(f));

  _activityLog: MrfiktivActivityLogViewModelGen | ThgActivityLogViewModelGen = {} as any;

  get activityLogs() {
    return this._paginationList;
  }

  @Action
  protected async loadDocuments(
    query: MrfiktivActivityLogControllerFindAllParamsGen | ThgActivityLogControllerFindAllParamsGen
  ): Promise<any> {
    return sharedActivityLogService.getAllForPartner(query);
  }

  @Action
  async loadDocumentsForReport(data: { partnerId: string; reportId: string }): Promise<any> {
    const reportActivities = await sharedActivityLogService.getAllByReportForPartner(data.partnerId, data.reportId);

    this.context.commit("mutateDocuments", reportActivities?.data);

    return reportActivities;
  }

  @Action
  async loadDocumentsForThg({ partnerId, thgId }: { partnerId: string; thgId: string }): Promise<any> {
    const activities = await sharedActivityLogService.getAllByReportForThg(partnerId, thgId);

    this.context.commit("mutateDocuments", activities?.data);

    return activities;
  }

  @Action
  async loadDocumentsForSource({
    partnerId,
    source
  }: {
    partnerId: string;
    source: MrfiktivReferenceGen | ThgReferenceGen;
  }): Promise<any> {
    const activities = await sharedActivityLogService.getAllBySource(partnerId, source);

    this.context.commit("mutateDocuments", activities?.data);

    return activities;
  }

  @Action
  async getAll(data: { partnerId: string }) {
    await this.context.dispatch("fetchAllFromBeginning", {
      partnerId: data.partnerId
    });

    this.context.commit("mutateDocuments", this._paginationList);
  }

  @Mutation
  mutateActivityLog(request: MrfiktivActivityLogViewModelGen | ThgActivityLogViewModelGen) {
    this._activityLog = request;
  }

  @Mutation
  mutateDocuments(requests: MrfiktivActivityLogViewModelGen[] | ThgActivityLogViewModelGen[]) {
    this._paginationList = requests;
  }

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreateActivityLogDtoGen | ThgCreateActivityLogDtoGen }) {
    const res = await sharedActivityLogService.create(data.partnerId, data.data);

    this.context.commit("mutateActivityLog", res);

    return res;
  }

  @Action
  async delete(data: { partnerId: string; activityId: string }) {
    const res = await sharedActivityLogService.removeForPartner(data.partnerId, data.activityId);
    this.context.commit("mutateActivityLog", res);

    return res;
  }

  @Action
  async getOne(data: { partnerId: string; activityId: string }) {
    const res = await sharedActivityLogService.getOneForPartner(data.partnerId, data.activityId);

    this.context.commit("mutateActivityLog", res);

    return res;
  }

  @Action
  async update(data: {
    partnerId: string;
    activityId: string;
    data: MrfiktivUpdateActivityLogDtoGen | ThgUpdateActivityLogDtoGen;
  }) {
    const res = await sharedActivityLogService.updateForPartner(data.partnerId, data.activityId, data.data);

    this.context.commit("mutateActivityLog", res);

    return res;
  }

  @Action
  async createAssigneeActivity(data: {
    source: MrfiktivReferenceGen;
    partnerId: string;
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE;
    newAssignees?: string[];
  }) {
    if (!data.newAssignees?.length) return;

    try {
      const activity = await ActivityLogModule.create({
        partnerId: data.partnerId,
        data: {
          source: {
            refType: data.source.refType,
            refId: data.source.refId
          },
          target: data.newAssignees.map(assignee => {
            return {
              refType: BackendResourceEnum.USER,
              refId: assignee
            };
          }),
          actionType: ActionEnum.UPDATE,
          activity: data.activityType
        }
      });

      ActivityLogModule.addToList(activity);

      return activity;
    } catch (e) {
      handleError(e);
    }
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "activity-log",
  store
})
export class ActivityLogStore extends ActivityLogBaseStore {}

export const ActivityLogModule = getModule(ActivityLogStore);
