























import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import FileInputMixin from "./FileInputMixin.vue";

@Component({
  components: {}
})
export default class FileInput extends mixins(FileInputMixin) {
  @Prop()
  value!: File;

  @Prop()
  rules!: ((value: any) => string | boolean)[];

  @Prop()
  label!: string;

  @Prop()
  accept!: string;

  @Prop()
  placeholder!: string;

  @Prop()
  readonly!: boolean;

  @Prop({ default: false })
  persistentHint!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: true })
  onlyPdf!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  get file() {
    return this.value;
  }

  set file(file: File) {
    this.setFile(file);
  }

  async handleFileHook(file: File) {
    this.$emit("update:value", file);
    this.$emit("change", file);
  }
}
