/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ILocalDataAccessLayer, IMapConfig } from "./local-data-access-layer.interface";

/**
 * Class for data access
 */
export abstract class AbstractLocalDataAccessLayer<T, MapKeys extends string = string>
  implements ILocalDataAccessLayer<T, MapKeys> {
  /**
   * @inheritdoc
   */
  private _entities: T[] = [];

  /**
   * A local map of the position of entities.
   */
  private _map = new Map<string, number>();

  /**
   * @inheritdoc
   */
  readonly mapsConfig = {} as Record<MapKeys, IMapConfig>;

  /**
   * List of maps to access entities by different keys configured in mapsConfig
   */
  protected _maps = {} as Record<MapKeys, Map<string, number[]>>;

  /**
   * Object containing maps to access entities by different keys configured in mapsConfig
   */
  get maps(): Record<MapKeys | "id", { get: (key: string) => T[] }> {
    let map = {
      id: {
        get: (id: string) => {
          const found = this.get(id);

          if (found !== undefined) {
            return [found];
          } else {
            return [];
          }
        }
      }
    } as Record<MapKeys | "id", { get: (key: string) => T[] }>;

    (Object.keys(this._maps) as MapKeys[]).forEach(key => {
      map = {
        ...map,
        // do not return entire map, but only give access to get method
        [key]: {
          get: (id: string) => this.getFromMap(id, key)
        }
      };
    });

    return map;
  }

  /**
   * @inheritdoc
   */
  abstract getIdentifier(entity: T): string;

  /**
   * Maps an entity to a new entity.
   * Can be used to extend the entity with additional properties, e.g. "billing.entity" adds additional getters and fetch methods
   * It can be overwritten by the concrete data access layer, default behavior does not change the entity.
   *
   * @param entity
   */
  protected mapEntity(entity: T): T {
    return entity;
  }

  /**
   * @inheritdoc
   */
  get(id: string): T | undefined {
    const pos = this._map.get(id);
    if (pos === undefined) {
      return undefined;
    }

    const mappedEntity = this.mapEntity(this._entities[pos]);

    return mappedEntity || undefined;
  }

  /**
   * Returns all entities that have the given id in one of the maps.
   *
   * @param id
   * @param mapKey
   * @returns
   */
  private getFromMap(id: string, mapKey: MapKeys) {
    const getIdentifier = this.mapsConfig[mapKey].getIdentifier;

    // get all indexes of entities referencing the given id
    const foundIndexes: number[] = this._maps[mapKey].get(id) ?? [];

    // get entities by index and map it to the frontend class
    const foundEntities = foundIndexes.map((index: number) => {
      const entity = this.mapEntity(this._entities[index]);
      return { entity, index };
    });

    // make sure that the identifier of the entity includes the given id, in case the entity was updated in the meantime
    const matchingEntities: T[] = [];
    const matchingIndexes: number[] = [];
    foundEntities.forEach(found => {
      const { entity, index } = found;
      const isMatch = getIdentifier(entity).includes(id);
      if (isMatch) {
        matchingEntities.push(entity);
        matchingIndexes.push(index);
      }
    });

    // update the map with the filtered indexes
    this._maps[mapKey].set(id, matchingIndexes);

    return matchingEntities;
  }
  /**
   * @inheritdoc
   */
  set(entity: T): T {
    const id = this.getIdentifier(entity);
    let pos = this._map.get(id);

    if (pos === undefined) {
      const mappedEntity = this.mapEntity(entity);
      this._entities.push(mappedEntity);
      this._map.set(id, this._entities.length - 1);
      pos = this._entities.length - 1;
    } else {
      const mappedEntity = this.mapEntity({ ...this._entities[pos], ...entity });
      this._entities.splice(pos, 1, mappedEntity);
    }

    for (const entry of Object.entries(this.mapsConfig)) {
      const name = entry[0] as MapKeys;
      const { getIdentifier } = entry[1] as IMapConfig;
      if (!this._maps[name]) {
        this._maps = {
          ...this._maps,
          [name]: new Map()
        };
      }

      for (const identifier of getIdentifier(entity) ?? []) {
        const found = this._maps[name].get(identifier) ?? [];
        if (!found.includes(pos)) {
          found.push(pos);
        }
        this._maps[name].set(identifier, found);
      }
    }

    return this._entities[pos] as T;
  }

  /**
   * @inheritdoc
   */
  delete(entity: T): T {
    const id = this.getIdentifier(entity);

    const indexOfDeleted = this._map.get(id);
    if (indexOfDeleted !== undefined) {
      this._entities.splice(indexOfDeleted, 1);
      const remainingEntities = [...this._entities];

      this._entities.splice(0);
      this._map.clear();
      Object.keys(this.mapsConfig).forEach(mapName => this._maps[mapName].clear());

      for (const entity of remainingEntities) {
        this.set(entity);
      }
    }

    return this.mapEntity(entity);
  }

  /**
   * @inheritdoc
   */
  clear() {
    this._map.clear();
    for (const entry of Object.entries(this.mapsConfig)) {
      const name = entry[0] as MapKeys;
      this._maps[name]?.clear();
    }
    this._entities.splice(0);
  }

  /**
   * @inheritdoc
   */
  get entities() {
    return this._entities.map(e => this.mapEntity(e));
  }

  /**
   * @inheritdoc
   */
  set entities(entities: T[]) {
    this.clear();
    this._entities.push(...entities);
    this._entities.forEach((entity, index) => {
      this._map.set(this.getIdentifier(entity), index);
      for (const entry of Object.entries(this.mapsConfig)) {
        const name = entry[0] as MapKeys;
        const { getIdentifier } = entry[1] as IMapConfig;
        for (const identifier of getIdentifier(entity) ?? []) {
          const found = this._maps[name].get(identifier) ?? [];

          if (!found.includes(index)) {
            found.push(index);
          }
          this._maps[name].set(identifier, found);
        }
      }
    });
  }
}
