/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateReportAsPartnerDtoGen,
  MrfiktivCreateReportDtoGen,
  MrfiktivCreateReportImageDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivExportResultViewModelGen,
  MrfiktivFindManyDtoGen,
  MrfiktivImageEditDataDtoGen,
  MrfiktivImageIdViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivRegistrationDtoGen,
  MrfiktivReportControllerCreateParamsGen,
  MrfiktivReportControllerCreateQueryParamsGen,
  MrfiktivReportControllerFindAllParamsGen,
  MrfiktivReportControllerUploadImageQueryParamsGen,
  MrfiktivReportViewModelGen,
  MrfiktivSharedReportViewmodelGen,
  MrfiktivUpdateProgressStatusDtoGen,
  MrfiktivUpdateReportDtoGen,
  MrfiktivUpdateReportImagesDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Report<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags report
   * @name ReportControllerCreateQuery
   * @summary Submit report form to contact a Partner.
   * @request POST:/partner/report
   * @response `200` `MrfiktivReportViewModelGen` The updated record
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `void` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerCreateQuery = (
    query: MrfiktivReportControllerCreateQueryParamsGen,
    data: MrfiktivCreateReportDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/report`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerCreateReportAsPartner
   * @summary Create a report
   * @request POST:/partner/{partnerId}/report/asPartner
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` A new report document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerCreateReportAsPartner = (
    partnerId: string,
    data: MrfiktivCreateReportAsPartnerDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, MrfiktivExceptionViewmodelGen | void>({
      path: `/partner/${partnerId}/report/asPartner`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerUploadImageQuery
   * @summary Submit image for a Report.
   * @request POST:/partner/report/image
   * @response `200` `MrfiktivImageIdViewmodelGen` The id of the image
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `void` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerUploadImageQuery = (
    query: MrfiktivReportControllerUploadImageQueryParamsGen,
    data: MrfiktivCreateReportImageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivImageIdViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/report/image`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerUploadImage
   * @summary Deprecated: Submit image for a Report.
   * @request POST:/partner/{partnerName}/report/image
   * @response `200` `MrfiktivImageIdViewmodelGen` The id of the image
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `void` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerUploadImage = (
    partnerName: string,
    data: MrfiktivCreateReportImageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivImageIdViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerName}/report/image`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerCreate
   * @summary Deprecated: Submit report form to contact a Partner.
   * @request POST:/partner/{partnerName}/report
   * @response `200` `MrfiktivReportViewModelGen` The updated record
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `void` No partner found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerCreate = (
    { partnerName, ...query }: MrfiktivReportControllerCreateParamsGen,
    data: MrfiktivCreateReportDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerName}/report`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerFindAll
   * @summary Get all reports
   * @request GET:/partner/{partnerId}/report
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivReportViewModelGen)[] })`
   * @response `400` `void` Bad Request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Report not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerFindAll = (
    { partnerId, ...query }: MrfiktivReportControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivReportViewModelGen[] },
      void | MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/report`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerFindOne
   * @summary Get a report by id
   * @request GET:/partner/{partnerId}/report/{reportId}
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The found record
   * @response `400` `void` Bad Request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Report not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerFindOne = (partnerId: string, reportId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerUpdateReport
   * @summary Update report
   * @request PATCH:/partner/{partnerId}/report/{reportId}
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The updated report document
   * @response `400` `void` Bad request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Document not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerUpdateReport = (
    partnerId: string,
    reportId: string,
    data: MrfiktivUpdateReportDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerDeleteReport
   * @summary Deletes a report by id (hard delete)
   * @request DELETE:/partner/{partnerId}/report/{reportId}
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The deleted report document
   * @response `400` `void` Bad request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Document not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerDeleteReport = (partnerId: string, reportId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerFindOneWithAccesSignature
   * @summary (DEPRECATED) Use shared content controller instead. Get a report by id with a shared access signature.
   * @request GET:/partner/{partnerId}/report/{reportId}/access-signature/{accessSignatureId}
   * @deprecated
   * @response `200` `MrfiktivSharedReportViewmodelGen` The found record
   * @response `400` `void` Bad Request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Report not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerFindOneWithAccesSignature = (
    partnerId: string,
    reportId: string,
    accessSignatureId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSharedReportViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}/access-signature/${accessSignatureId}`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerAddRegistration
   * @summary Add an registration details to a report
   * @request POST:/partner/{partnerId}/report/{reportId}/registration
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The updated report document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerAddRegistration = (
    partnerId: string,
    reportId: string,
    data: MrfiktivRegistrationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, MrfiktivExceptionViewmodelGen | void>({
      path: `/partner/${partnerId}/report/${reportId}/registration`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerExportOne
   * @summary Get the export of a report
   * @request GET:/partner/{partnerId}/report/{reportId}/export/{exportType}
   * @secure
   * @response `200` `MrfiktivExportResultViewModelGen` The url of the found record's export
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerExportOne = (partnerId: string, reportId: string, exportType: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivExportResultViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}/export/${exportType}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerArchiveMany
   * @summary (Ticket - UPDATE) Sets all tickets from a list to reports for a partner
   * @request PATCH:/partner/{partnerId}/report/archive
   * @secure
   * @response `200` `(MrfiktivReportViewModelGen)[]` The updated reports
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerArchiveMany = (partnerId: string, data: MrfiktivFindManyDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivReportViewModelGen[], MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/archive`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerUpdateReportImages
   * @summary Update images
   * @request PATCH:/partner/{partnerId}/report/{reportId}/image
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The updated report document
   * @response `400` `void` Bad request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Document not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerUpdateReportImages = (
    partnerId: string,
    reportId: string,
    data: MrfiktivUpdateReportImagesDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}/image`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerEditReportImage
   * @summary Update a certain image's rotation in a report
   * @request PATCH:/partner/{partnerId}/report/{reportId}/image/{imageId}
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The updated report document
   * @response `400` `void` Bad request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Document not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerEditReportImage = (
    partnerId: string,
    reportId: string,
    imageId: string,
    data: MrfiktivImageEditDataDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}/image/${imageId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags report
   * @name ReportControllerUpdateProgressStatus
   * @summary Update progress status of report
   * @request PATCH:/partner/{partnerId}/report/{reportId}/status
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The updated report document
   * @response `400` `void` Partner id mismatch. Bad request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Report/ Partner not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportControllerUpdateProgressStatus = (
    partnerId: string,
    reportId: string,
    data: MrfiktivUpdateProgressStatusDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/report/${reportId}/status`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
