/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivDaControllerGetCustomersParamsGen,
  MrfiktivDaControllerGetFreeSlotParamsGen,
  MrfiktivDaControllerGetServiceEventParamsGen,
  MrfiktivDaControllerGetServiceEventsParamsGen,
  MrfiktivDaCustomerViewModelGen,
  MrfiktivDaEventTypesViewModelGen,
  MrfiktivDaExportReportDtoGen,
  MrfiktivDaFreeSlotViewModelGen,
  MrfiktivDaResourcesViewModelGen,
  MrfiktivDAServiceEventDetailViewModelGen,
  MrfiktivDaSetupDtoGen,
  MrfiktivDaSetupViewModelGen,
  MrfiktivDaWorkshopServicesViewModelGen,
  MrfiktivDaWorkshopTasksViewModelGen,
  MrfiktivOperationIdViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Da<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetResources
   * @summary Get a list of resources for the digital autohaus
   * @request GET:/partner/{partnerId}/da/resources
   * @secure
   * @response `200` `MrfiktivDaResourcesViewModelGen` The available resources
   */
  daControllerGetResources = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivDaResourcesViewModelGen, any>({
      path: `/partner/${partnerId}/da/resources`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetEventTypes
   * @summary Get a list of event types for the digital autohaus
   * @request GET:/partner/{partnerId}/da/eventTypes
   * @secure
   * @response `200` `MrfiktivDaEventTypesViewModelGen` The available resources
   */
  daControllerGetEventTypes = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivDaEventTypesViewModelGen, any>({
      path: `/partner/${partnerId}/da/eventTypes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetServiceEvents
   * @summary Get a list of service events for the digital autohaus
   * @request GET:/partner/{partnerId}/da/serviceEvents
   * @secure
   * @response `200` `(MrfiktivDAServiceEventDetailViewModelGen)[]` The available service events
   */
  daControllerGetServiceEvents = (
    { partnerId, ...query }: MrfiktivDaControllerGetServiceEventsParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivDAServiceEventDetailViewModelGen[], any>({
      path: `/partner/${partnerId}/da/serviceEvents`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetCustomers
   * @summary Get a list of customers from the digital autohaus
   * @request GET:/partner/{partnerId}/da/customers
   * @secure
   * @response `200` `(MrfiktivDaCustomerViewModelGen)[]` The available service events
   */
  daControllerGetCustomers = (
    { partnerId, ...query }: MrfiktivDaControllerGetCustomersParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivDaCustomerViewModelGen[], any>({
      path: `/partner/${partnerId}/da/customers`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetWorkshopServices
   * @summary Get a list of workshop services for the digital autohaus
   * @request GET:/partner/{partnerId}/da/workshopServices
   * @secure
   * @response `200` `MrfiktivDaWorkshopServicesViewModelGen` The available services
   */
  daControllerGetWorkshopServices = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivDaWorkshopServicesViewModelGen, any>({
      path: `/partner/${partnerId}/da/workshopServices`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetWorkshopTasks
   * @summary Get a list of workshop tasks for the digital autohaus
   * @request GET:/partner/{partnerId}/da/workshopTasks
   * @secure
   * @response `200` `MrfiktivDaWorkshopTasksViewModelGen` The available tasks
   */
  daControllerGetWorkshopTasks = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivDaWorkshopTasksViewModelGen, any>({
      path: `/partner/${partnerId}/da/workshopTasks`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetFreeSlot
   * @summary Get free slot
   * @request GET:/partner/{partnerId}/da/freeSlot
   * @response `200` `MrfiktivDaFreeSlotViewModelGen` The free slot
   */
  daControllerGetFreeSlot = (
    { partnerId, ...query }: MrfiktivDaControllerGetFreeSlotParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivDaFreeSlotViewModelGen, any>({
      path: `/partner/${partnerId}/da/freeSlot`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerSetup
   * @summary Setup the DA configuration.
   * @request POST:/partner/{partnerId}/da
   * @secure
   * @response `201` `MrfiktivDaSetupViewModelGen` Setup is completed
   */
  daControllerSetup = (partnerId: string, data: MrfiktivDaSetupDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivDaSetupViewModelGen, any>({
      path: `/partner/${partnerId}/da`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerIsSetup
   * @summary Get Setup status  DA configuration.
   * @request GET:/partner/{partnerId}/da
   * @secure
   * @response `200` `MrfiktivDaSetupViewModelGen` Setup status
   */
  daControllerIsSetup = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivDaSetupViewModelGen, any>({
      path: `/partner/${partnerId}/da`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerExport
   * @summary Export a report to the DA
   * @request POST:/partner/{partnerId}/da/{reportId}
   * @secure
   * @response `201` `MrfiktivOperationIdViewModelGen` Successfully queued
   */
  daControllerExport = (
    partnerId: string,
    reportId: string,
    data: MrfiktivDaExportReportDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivOperationIdViewModelGen, any>({
      path: `/partner/${partnerId}/da/${reportId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags da
   * @name DaControllerGetServiceEvent
   * @summary Get the DA document for a serviceEventId
   * @request GET:/partner/{partnerId}/serviceEvent/{serviceEventId}
   * @secure
   * @response `200` `MrfiktivDAServiceEventDetailViewModelGen` Successfully retrieved
   */
  daControllerGetServiceEvent = (
    { partnerId, serviceEventId, ...query }: MrfiktivDaControllerGetServiceEventParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivDAServiceEventDetailViewModelGen, any>({
      path: `/partner/${partnerId}/serviceEvent/${serviceEventId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
