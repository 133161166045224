/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgBaseChartItemListViewmodelGen,
  ThgBaseTrendAnalysisViewmodelGen,
  ThgCustomerDataViewModelGen,
  ThgExceptionViewmodelGen,
  ThgThgAnalyticsControllerAffiliateDistributionForPartnerParamsGen,
  ThgThgAnalyticsControllerAffiliatedistributionParamsGen,
  ThgThgAnalyticsControllerCustomerDataByPartnerIdParamsGen,
  ThgThgAnalyticsControllerHourAnalysisParamsGen,
  ThgThgAnalyticsControllerHourAnalysisPerPartnerParamsGen,
  ThgThgAnalyticsControllerImpactTypeDistributionForPartnerParamsGen,
  ThgThgAnalyticsControllerImpactTypeDistributionParamsGen,
  ThgThgAnalyticsControllerIsMultiyearDistributionParamsGen,
  ThgThgAnalyticsControllerIsMultiyeardistributionPerPartnerParamsGen,
  ThgThgAnalyticsControllerMeterReadingtrendAnalysisForPartnerParamsGen,
  ThgThgAnalyticsControllerMeterReadingTrendAnalysisParamsGen,
  ThgThgAnalyticsControllerPartnerdistributionParamsGen,
  ThgThgAnalyticsControllerStatiDistributionForPartnerParamsGen,
  ThgThgAnalyticsControllerStatiDistributionParamsGen,
  ThgThgAnalyticsControllerThgPerUserDistributionParamsGen,
  ThgThgAnalyticsControllerThgPromotionCountTrendAnalysisParamsGen,
  ThgThgAnalyticsControllerThgPromotionTrendAnalysisForPartnerParamsGen,
  ThgThgAnalyticsControllerThgPromotionTrendAnalysisParamsGen,
  ThgThgAnalyticsControllerTrendAnalysisForPartnerParamsGen,
  ThgThgAnalyticsControllerTrendAnalysisParamsGen,
  ThgThgAnalyticsControllerUserIsCompanyParamsGen,
  ThgThgAnalyticsControllerUserMarketingOptInParamsGen,
  ThgThgAnalyticsControllerUserTrendAnalysisParamsGen,
  ThgThgAnalyticsControllerVehicleClassDistributionForPartnerParamsGen,
  ThgThgAnalyticsControllerVehicleClassDistributionParamsGen,
  ThgThgAnalyticsControllerVinAnalysisPerPartnerParamsGen,
  ThgThgAnalyticsControllerWeekdayAnalysisParamsGen,
  ThgThgAnalyticsControllerWeekdayAnalysisPerPartnerParamsGen,
  ThgThgAnalyticsControllerYeardistributionParamsGen,
  ThgThgAnalyticsControllerYeardistributionPerPartnerParamsGen,
  ThgThgImpactTypeAnalyticsViewmodelGen,
  ThgThgPartnerAnalysisViewmodelGen,
  ThgThgRealtimeAnalysisViewmodelGen,
  ThgThgStatusDistributionAnalyticsViewmodelGen,
  ThgThgUserStatsViewmodelGen,
  ThgThgYearDistributionAnalyticsViewmodelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class ThgAnalytics<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerRealtime
   * @summary Return trendanalysis for day, week and 30 days
   * @request GET:/thg/analytics/realtime
   * @secure
   * @response `200` `ThgThgRealtimeAnalysisViewmodelGen` Thg trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerRealtime = (params: RequestParams = {}) =>
    this.http.request<ThgThgRealtimeAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/realtime`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerGetAvailableCountries
   * @summary Return countries where THG is offered
   * @request GET:/thg/analytics/availableCountries
   * @secure
   * @response `200` `(string)[]` List of available countries
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerGetAvailableCountries = (params: RequestParams = {}) =>
    this.http.request<string[], void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/availableCountries`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerGetAvailableYears
   * @summary Return years for which a THG can be submitted
   * @request GET:/thg/analytics/availableYears
   * @secure
   * @response `200` `(number)[]` List of available years
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerGetAvailableYears = (params: RequestParams = {}) =>
    this.http.request<number[], void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/availableYears`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerTrendAnalysis
   * @summary Return trendanalysis for day, week and 30 days
   * @request GET:/thg/analytics/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` Thg trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerTrendAnalysis = (
    query: ThgThgAnalyticsControllerTrendAnalysisParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerImpactTypeDistribution
   * @summary Returns the distribution of impact
   * @request GET:/thg/analytics/impact
   * @secure
   * @response `200` `ThgThgImpactTypeAnalyticsViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerImpactTypeDistribution = (
    query: ThgThgAnalyticsControllerImpactTypeDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgImpactTypeAnalyticsViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/impact`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerThgPerUserDistribution
   * @summary Returns the distribution of thgs per user
   * @request GET:/thg/analytics/thgPerUserDistribution
   * @secure
   * @response `200` `ThgThgUserStatsViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerThgPerUserDistribution = (
    query: ThgThgAnalyticsControllerThgPerUserDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgUserStatsViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/thgPerUserDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerPartnerdistribution
   * @summary Returns the distribution of partners
   * @request GET:/thg/analytics/partnerDistribution
   * @secure
   * @response `200` `ThgThgPartnerAnalysisViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerPartnerdistribution = (
    query: ThgThgAnalyticsControllerPartnerdistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgPartnerAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/partnerDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerAffiliatedistribution
   * @summary Returns the aggregation of used affiliates
   * @request GET:/thg/analytics/affiliateDistribution
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerAffiliatedistribution = (
    query: ThgThgAnalyticsControllerAffiliatedistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/affiliateDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerWeekdayAnalysis
   * @summary Returns the distribution of weekdays
   * @request GET:/thg/analytics/weekdayAnalysis
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact weekdays
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerWeekdayAnalysis = (
    query: ThgThgAnalyticsControllerWeekdayAnalysisParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/weekdayAnalysis`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerHourAnalysis
   * @summary Returns the distribution of hours
   * @request GET:/thg/analytics/hourAnalysis
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the converions per hour
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerHourAnalysis = (
    query: ThgThgAnalyticsControllerHourAnalysisParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/hourAnalysis`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerStatiDistribution
   * @summary Returns the aggregation of status distribution
   * @request GET:/thg/analytics/statusDistribution
   * @secure
   * @response `200` `ThgThgStatusDistributionAnalyticsViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerStatiDistribution = (
    query: ThgThgAnalyticsControllerStatiDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgStatusDistributionAnalyticsViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/statusDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerYeardistribution
   * @summary Returns the aggregation of year distribution
   * @request GET:/thg/analytics/yearDistribution
   * @secure
   * @response `200` `ThgThgYearDistributionAnalyticsViewmodelGen` Distribution of the year
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerYeardistribution = (
    query: ThgThgAnalyticsControllerYeardistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgYearDistributionAnalyticsViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/yearDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerIsMultiyearDistribution
   * @summary Returns the aggregation of multi year distribution
   * @request GET:/thg/analytics/isMultiyearDistribution
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the flag is multi year
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerIsMultiyearDistribution = (
    query: ThgThgAnalyticsControllerIsMultiyearDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/isMultiyearDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerVehicleClassDistribution
   * @summary Returns the aggregation of used affiliates
   * @request GET:/thg/analytics/vehicleClassDistribution
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerVehicleClassDistribution = (
    query: ThgThgAnalyticsControllerVehicleClassDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/analytics/vehicleClassDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerRealtimeForPartner
   * @summary Return trendanalysis for day, week and 30 days
   * @request GET:/partner/{partnerId}/thg/analytics/realtime
   * @secure
   * @response `200` `ThgThgRealtimeAnalysisViewmodelGen` Thg trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerRealtimeForPartner = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<ThgThgRealtimeAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/thg/analytics/realtime`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerTrendAnalysisForPartner
   * @summary Return trendanalysis for day, week and 30 days
   * @request GET:/partner/{partnerId}/thg/analytics/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` Thg trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerTrendAnalysisForPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerTrendAnalysisForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/thg/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerMeterReadingtrendAnalysisForPartner
   * @summary Return trendanalysis for day, week and 30 days
   * @request GET:/partner/{partnerId}/meter-reading/analytics/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` MeterReading trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerMeterReadingtrendAnalysisForPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerMeterReadingtrendAnalysisForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/meter-reading/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerThgPromotionTrendAnalysisForPartner
   * @summary Return thg promotion trendanalysis for day, week and 30 days
   * @request GET:/partner/{partnerId}/thg/promotion/analytics/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` Thg promotion trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerThgPromotionTrendAnalysisForPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerThgPromotionTrendAnalysisForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/thg/promotion/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerImpactTypeDistributionForPartner
   * @summary Returns the distribution of impact
   * @request GET:/partner/{partnerId}/thg/analytics/impact
   * @secure
   * @response `200` `ThgThgImpactTypeAnalyticsViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerImpactTypeDistributionForPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerImpactTypeDistributionForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgImpactTypeAnalyticsViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/thg/analytics/impact`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerAffiliateDistributionForPartner
   * @summary Returns the aggregation of used affiliates
   * @request GET:/partner/{partnerId}/analytics/affiliateDistribution
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerAffiliateDistributionForPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerAffiliateDistributionForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/affiliateDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerStatiDistributionForPartner
   * @summary Returns the aggregation of status distribution
   * @request GET:/partner/{partnerId}/analytics/statusDistribution
   * @secure
   * @response `200` `ThgThgStatusDistributionAnalyticsViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerStatiDistributionForPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerStatiDistributionForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgStatusDistributionAnalyticsViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/statusDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerVehicleClassDistributionForPartner
   * @summary Returns the aggregation of used affiliates
   * @request GET:/partner/{partnerId}/analytics/vehicleClassDistribution
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerVehicleClassDistributionForPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerVehicleClassDistributionForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/vehicleClassDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerWeekdayAnalysisPerPartner
   * @summary Returns the aggregation of used affiliates
   * @request GET:/partner/{partnerId}/analytics/weekdayAnalysis
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerWeekdayAnalysisPerPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerWeekdayAnalysisPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/weekdayAnalysis`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerHourAnalysisPerPartner
   * @summary Returns the aggregation of conversions per hour
   * @request GET:/partner/{partnerId}/analytics/hourAnalysis
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the conversion per hour
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerHourAnalysisPerPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerHourAnalysisPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/hourAnalysis`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerVinAnalysisPerPartner
   * @summary Returns the vins incl. year of registration for a partner
   * @request GET:/partner/{partnerId}/analytics/vinAnalysis
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Returns registered vins and the year of registration
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerVinAnalysisPerPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerVinAnalysisPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/vinAnalysis`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerYeardistributionPerPartner
   * @summary Returns the aggregation of year distribution
   * @request GET:/partner/{partnerId}/analytics/yearDistribution
   * @secure
   * @response `200` `ThgThgYearDistributionAnalyticsViewmodelGen` Distribution of the year
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerYeardistributionPerPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerYeardistributionPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgYearDistributionAnalyticsViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/yearDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerIsMultiyeardistributionPerPartner
   * @summary Returns the aggregation of multiyear distribution
   * @request GET:/partner/{partnerId}/analytics/isMultiyearDistribution
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the year
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerIsMultiyeardistributionPerPartner = (
    { partnerId, ...query }: ThgThgAnalyticsControllerIsMultiyeardistributionPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/isMultiyearDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerCustomerDataByPartnerId
   * @summary Returns the customer data for a given partner
   * @request GET:/partner/{partnerId}/customer-data
   * @secure
   * @response `200` `ThgCustomerDataViewModelGen` Customer data in a time period
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerCustomerDataByPartnerId = (
    { partnerId, ...query }: ThgThgAnalyticsControllerCustomerDataByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgCustomerDataViewModelGen, void | ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/customer-data`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerUserTrendAnalysis
   * @summary Return trendanalysis for day, week and 30 days of registrated user
   * @request GET:/user/analytics/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` Thg trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerUserTrendAnalysis = (
    query: ThgThgAnalyticsControllerUserTrendAnalysisParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/user/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerUserRealTime
   * @summary Return real time data of registered user of the past 12 hours
   * @request GET:/user/analytics/realtime
   * @secure
   * @response `200` `ThgThgRealtimeAnalysisViewmodelGen` Thg trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerUserRealTime = (params: RequestParams = {}) =>
    this.http.request<ThgThgRealtimeAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/user/analytics/realtime`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerUserMarketingOptIn
   * @summary Returns the distribution of user marketing opt in
   * @request GET:/user/analytics/marketingOptIn
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerUserMarketingOptIn = (
    query: ThgThgAnalyticsControllerUserMarketingOptInParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/user/analytics/marketingOptIn`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerUserIsCompany
   * @summary Returns the b2b / b2c distribution of our customers
   * @request GET:/user/analytics/isCompany
   * @secure
   * @response `200` `ThgBaseChartItemListViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerUserIsCompany = (
    query: ThgThgAnalyticsControllerUserIsCompanyParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseChartItemListViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/user/analytics/isCompany`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerMeterReadingTrendAnalysis
   * @summary Return trendanalysis for day, week and 30 days of registrated meter reading
   * @request GET:/meter-reading/analytics/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` Thg trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerMeterReadingTrendAnalysis = (
    query: ThgThgAnalyticsControllerMeterReadingTrendAnalysisParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/meter-reading/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerThgPromotionTrendAnalysis
   * @summary Return trendanalysis for day, week and 30 days of promotions
   * @request GET:/thg/promotion/analytics/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` Thg promotion trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerThgPromotionTrendAnalysis = (
    query: ThgThgAnalyticsControllerThgPromotionTrendAnalysisParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/promotion/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags thgAnalytics
   * @name ThgAnalyticsControllerThgPromotionCountTrendAnalysis
   * @summary Return thg promotion count trendanalysis for day, week and 30 days
   * @request GET:/thg/promotion/analytics/count/trend
   * @secure
   * @response `200` `ThgBaseTrendAnalysisViewmodelGen` Thg promotion trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgAnalyticsControllerThgPromotionCountTrendAnalysis = (
    query: ThgThgAnalyticsControllerThgPromotionCountTrendAnalysisParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBaseTrendAnalysisViewmodelGen, void | ThgExceptionViewmodelGen>({
      path: `/thg/promotion/analytics/count/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
