























import { PartnerEntity } from "@/models/partnerEntity";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/lib/eventBus";

@Component
export default class ThePartnerSelectorList extends Vue {
  loading = false;

  set partner(partner: PartnerEntity) {
    PartnerModule.setPartner(partner);
    eventBus.$emit("partnerLoaded");

    this.$router.push({ name: ConfigModule.partnerSelectorDestinationVue, params: { partnerId: partner._id } });
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get name() {
    return PartnerModule.partner.companyName;
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  getAddressText(partner: PartnerEntity) {
    const zip = partner?.address?.zip || "";
    const city = partner?.address?.city || "";
    const street = partner?.address?.street || "";

    return `${street} ${zip} ${city}`;
  }

  get items() {
    return [
      {
        icon: "mdi-view-dashboard-outline",
        text: "Übersicht",
        link: `/partners/${this.partnerId}/home`
      },
      {
        icon: "mdi-car-outline",
        text: "Schadensmeldungen",
        link: `/partners/${this.partnerId}/report`
      },
      {
        icon: "mdi-message-outline",
        text: "Landingpage",
        link: `/partners/${this.partnerId}/form`
      }
    ];
  }
}
