




import { Component, Vue } from "vue-property-decorator";
import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import TheNavigationLayout from "./TheNavigationLayout.vue";
import { PartnerModule } from "@/store/modules/partner";

@Component({ components: { TheNavigationLayout } })
export default class TheAboutList extends Vue {
  get items(): IMenuListItem[] {
    return [
      {
        text: "navigation.TheAboutList.faq",
        link: "/faq",
        icon: "mdi-frequently-asked-questions",
        isEnabled: this.isFaq
      },
      {
        text: "navigation.TheAboutList.imprint",
        link: "/imprint",
        icon: "mdi-information-outline",
        isEnabled: true
      },
      {
        text: "navigation.TheAboutList.privacy",
        link: "/privacy",
        icon: "mdi-shield-sun-outline",
        isEnabled: true
      },
      {
        text: "navigation.TheAboutList.terms",
        link: "/agbs",
        icon: "mdi-handshake-outline",
        isEnabled: true
      },
      {
        text: "navigation.TheAboutList.settings",
        link: "/settings",
        icon: "mdi-tune-variant",
        isEnabled: true
      }
    ];
  }

  /**
   */
  get isFaq(): boolean {
    return PartnerModule.partner.settings?.bookedServices.faq ?? false;
  }
}
