























































import { PartnerColor } from "@/lib/partnerColor";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Card extends DarkModeHighlightMixin {
  @Prop()
  title!: string;

  @Prop({ default: false })
  hideTitle?: string;

  @Prop()
  subtitle?: string;

  @Prop({ default: false })
  isExpandable?: boolean;

  @Prop({ default: true })
  outlined?: boolean;

  @Prop({ default: false })
  hideDivider?: boolean;

  @Prop({ default: true })
  isInitiallyExpanded?: boolean;

  @Prop({ default: 2 })
  margin?: number;

  @Prop({ default: 250 })
  minWidth?: number;

  @Prop()
  width?: string;

  @Prop()
  height?: number;

  @Prop({ default: false })
  showGradient?: boolean;

  @Prop({ default: false })
  loading?: boolean;

  partnerColor = new PartnerColor();

  get gradientStyle() {
    if (this.showGradient) {
      return this.partnerColor.gradientStyle;
    }
    return "";
  }

  isExpanded = this.isInitiallyExpanded;
}
