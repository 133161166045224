/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * @description
 * Progress of report
 */
export enum ProgressStatusEnum {
  NEW = "new",
  IN_PROGRESS = "inProgress",
  FINISHED = "finished",
  DELETED = "deleted"
}
