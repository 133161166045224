import { IImageResizeOptions } from "../interfaces/image-resize-options.interface";
import { isImage } from "./ImageHelper";

/**
 * creates an blob from dataURI
 * @param dataURI
 * @returns
 */
export function dataURItoBlob(dataURI: string): Blob {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0 ? atob(dataURI.split(",")[1]) : unescape(dataURI.split(",")[1]);
  const mime = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
}

/**
 * resizes an Image
 * @param file
 * @param options specifies the outputtype, size and quality
 * @returns
 */
export async function resizeImage(file: File, options: IImageResizeOptions): Promise<Blob> {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");

  const resize = () => {
    let { width, height } = image;
    if (width > height) {
      if (width > options.maxWidth) {
        //height *= max_width / width;
        height = Math.round((height *= options.maxWidth / width));
        width = options.maxWidth;
      }
    } else {
      if (height > options.maxHeight) {
        //width *= max_height / height;
        width = Math.round((width *= options.maxHeight / height));
        height = options.maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(image, 0, 0, width, height);
    }

    const dataUrl = canvas.toDataURL(options.outputType, options.outputQuality);

    return dataURItoBlob(dataUrl);
  };

  return await new Promise((ok, no) => {
    if (!isImage(file)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = readerEvent => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target?.result as string;
    };

    return reader.readAsDataURL(file);
  });
}
