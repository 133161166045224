import { Dictionary } from "vue-router/types/router";

export class AssetRepository {
  public static ASSET_REPOSITORY: Dictionary<AssetTheme> = {
    logo: {
      light: require("@/assets/logo.png")
    },
    mmmint: {
      light: require("@/assets/mmmint.ai.png")
    },
    wirkaufendeinethg: {
      light: require("@/assets/wkdthg-light.png"),
      dark: require("@/assets/wkdthg-dark.png")
    },
    forest: {
      light: require("@/assets/forest-unsplash.jpg")
    },
    charging: {
      light: "https://static.mmmint.ai/wirkaufendeinethg/photo-1593941707882-a5bba14938c7.jpeg"
    },
    cockpit: {
      light: require("@/assets/cockpit.svg")
    },
    cockpitOutline: {
      light: require("@/assets/cockpit_outline.svg")
    },
    cockpitExample: {
      light: require("@/assets/instructions/cockpit_blaupause.gif"),
      dark: require("@/assets/instructions/cockpit_blaupause_dark.gif")
    },
    cockpitInstruction: {
      light: require("@/assets/instructions/cockpit.jpeg")
    },
    carFrontLeft: {
      light: require("@/assets/car_front_left.svg")
    },
    carFrontRight: {
      light: require("@/assets/car_front_right.svg")
    },
    carRearLeft: {
      light: require("@/assets/car_rear_left.svg")
    },
    carRearRight: {
      light: require("@/assets/car_rear_right.svg")
    },
    containerFrontLeft: {
      light: require("@/assets/containerFrontLeft.svg")
    },
    containerFrontRight: {
      light: require("@/assets/containerFrontRight.svg")
    },
    containerRearLeft: {
      light: require("@/assets/containerRearLeft.svg")
    },
    containerRearRight: {
      light: require("@/assets/containerRearRight.svg")
    },
    containerFrontLeftOutline: {
      light: require("@/assets/containerFrontLeftOutline.svg")
    },
    containerFrontRightOutline: {
      light: require("@/assets/containerFrontRightOutline.svg")
    },
    containerRearLeftOutline: {
      light: require("@/assets/containerRearLeftOutline.svg")
    },
    containerRearRightOutline: {
      light: require("@/assets/containerRearRightOutline.svg")
    },
    motorbikeFrontLeft: {
      light: require("@/assets/motorbikeFrontLeft.svg")
    },
    motorbikeFrontRight: {
      light: require("@/assets/motorbikeFrontRight.svg")
    },
    motorbikeRearLeft: {
      light: require("@/assets/motorbikeRearLeft.svg")
    },
    motorbikeRearRight: {
      light: require("@/assets/motorbikeRearRight.svg")
    },
    motorbikeTop: {
      light: require("@/assets/motorbikeTop.svg")
    },
    motorbikeTopOutline: {
      light: require("@/assets/motorbikeTopOutline.svg")
    },
    motorbikeFrontLeftOutline: {
      light: require("@/assets/motorbikeFrontLeftOutline.svg")
    },
    motorbikeFrontRightOutline: {
      light: require("@/assets/motorbikeFrontRightOutline.svg")
    },
    motorbikeRearLeftOutline: {
      light: require("@/assets/motorbikeRearLeftOutline.svg")
    },
    motorbikeRearRightOutline: {
      light: require("@/assets/motorbikeRearRightOutline.svg")
    },
    motorbikeFront: {
      light: require("@/assets/motorbikeOverview2.svg")
    },
    motorbikeFrontOutline: {
      light: require("@/assets/motorbikeOverview2Outline.svg")
    },
    motorbikeLeft: {
      light: require("@/assets/motorbikeOverview1.svg")
    },
    motorbikeLeftOutline: {
      light: require("@/assets/motorbikeOverview1Outline.svg")
    },
    motorbikeRight: {
      light: require("@/assets/motorbikeOverview3.svg")
    },
    motorbikeRightOutline: {
      light: require("@/assets/motorbikeOverview3Outline.svg")
    },
    motorbikeRear: {
      light: require("@/assets/motorbikeOverview4.svg")
    },
    motorbikeRearOutline: {
      light: require("@/assets/motorbikeOverview4Outline.svg")
    },
    damageOverviewContainerInstruction: {
      light: require("@/assets/instructions/damageOverviewContainer.png")
    },
    damageDetail: {
      light: require("@/assets/car_damage_detail.svg")
    },
    carFrontLeftOutline: {
      light: require("@/assets/car_front_left_outline.svg")
    },
    carFrontRightOutline: {
      light: require("@/assets/car_front_right_outline.svg")
    },
    carRearLeftOutline: {
      light: require("@/assets/car_rear_left_outline.svg")
    },
    carRearRightOutline: {
      light: require("@/assets/car_rear_right_outline.svg")
    },
    damageDetailOutline: {
      light: require("@/assets/car_damage_detail_outline.svg")
    },
    damageDetailExample: {
      light: require("@/assets/instructions/damage_detail_blaupause.gif"),
      dark: require("@/assets/instructions/damage_detail_blaupause_dark.gif")
    },
    damage: {
      light: require("@/assets/car_damage.svg")
    },
    scene: {
      light: require("@/assets/scene.svg")
    },
    damageOutline: {
      light: require("@/assets/car_damage_outline.svg")
    },
    overview: {
      light: require("@/assets/instructions/overview_blaupause.gif"),
      dark: require("@/assets/instructions/overview_blaupause_dark.gif")
    },
    registration: {
      light: require("@/assets/registration.svg")
    },
    registrationSchema: {
      light: require("@/assets/registration_form_schema.svg")
    },
    registrationBack: {
      light: require("@/assets/registration_back.svg")
    },
    registrationBackOutline: {
      light: require("@/assets/registration_back_outline.svg")
    },
    registrationExample: {
      light: require("@/assets/instructions/registration_blaupause.gif"),
      dark: require("@/assets/instructions/registration_blaupause_dark.gif")
    },
    registrationCorrectInstructions: {
      light: require("@/assets/instructions/beispielrichtig.gif")
    },
    registrationIncorrectInstructions: {
      light: require("@/assets/instructions/beispielfalsch.gif")
    },
    registrationAt: {
      light: require("@/assets/registration_at.svg")
    },
    registrationAtSchema: {
      light: require("@/assets/registration_at_schema.svg")
    },
    registrationAtBack: {
      light: require("@/assets/registration_at_back.svg")
    },
    registrationAtBackSchema: {
      light: require("@/assets/registration_at_schema_back.svg")
    },
    registrationAtExample: {
      light: require("@/assets/instructions/registration_at_blaupause.webp") // FIXME
    },
    registrationAtCorrectInstructions: {
      light: require("@/assets/instructions/registration_at_example.gif")
    },
    registrationAtIncorrectInstructions: {
      light: require("@/assets/instructions/registration_at_example_old.gif")
    },
    damageOverviewBehindInstruction: {
      light: require("@/assets/instructions/overview_behind.png")
    },
    damageOverviewFrontInstruction: {
      light: require("@/assets/instructions/overview_front.png")
    },
    damageDetailInstruction: {
      light: require("@/assets/instructions/damage_detail.png")
    },
    damageInstruction: {
      light: require("@/assets/instructions/damage.png")
    },
    damageExample: {
      light: require("@/assets/instructions/damage_blaupause.gif"),
      dark: require("@/assets/instructions/damage_blaupause_dark.gif")
    },
    emptyData: {
      light: require("@/assets/undraw/undraw_no_data_re_kwbl.svg")
    },
    loading: {
      light: require("@/assets/undraw/undraw_loading_re_5axr.svg")
    },
    towingTruck: {
      light: require("@/assets/undraw_towing_6yy4.svg")
    },
    fixingBugs: {
      light: require("@/assets/undraw/undraw_fixing_bugs.svg")
    },
    qaEngineer: {
      light: require("@/assets/undraw/undraw_qa_engineers.svg")
    },
    warning: {
      light: require("@/assets/undraw/undraw_warning.svg")
    },
    serverDown: {
      light: require("@/assets/undraw/undraw_server_down.svg")
    },
    tuev: {
      light: require("@/assets/tuev.svg")
    },
    tire: {
      light: require("@/assets/tire.png")
    },
    tireExample: {
      light: require("@/assets/tireExample.svg")
    },
    tireExampleAlt: {
      light: require("@/assets/tireExampleAlt.svg")
    },
    tireExampleOutlined: {
      light: require("@/assets/tireExampleOutlined.svg")
    },
    vinExample: {
      light: require("@/assets/vinExample.svg")
    },
    vinExmapleOutlined: {
      light: require("@/assets/vinExmapleOutlined.svg")
    },
    driversLicenseExampleFront: {
      light: require("@/assets/driversLicenseExampleFront.svg")
    },
    driversLicenseExampleFrontOutlined: {
      light: require("@/assets/driversLicenseExampleFrontOutlined.svg")
    },
    driversLicenseExampleBack: {
      light: require("@/assets/driversLicenseExampleBack.svg")
    },
    driversLicenseExampleBackOutlined: {
      light: require("@/assets/driversLicenseExampleBackOutlined.svg")
    },
    numberplateMotorbike: {
      light: require("@/assets/numberplateMotorbike.svg")
    },
    numberplate: {
      light: require("@/assets/numberplate.svg")
    },
    noImage: {
      light: require("@/assets/noImage.svg")
    },
    truckFrontLeft: {
      light: require("@/assets/truckFrontLeft.svg")
    },
    truckFrontRight: {
      light: require("@/assets/truckFrontRight.svg")
    },
    truckRearLeft: {
      light: require("@/assets/truckRearLeft.svg")
    },
    truckRearRight: {
      light: require("@/assets/truckRearRight.svg")
    },
    truckFrontLeftOutline: {
      light: require("@/assets/truckFrontLeftOutline.svg")
    },
    truckFrontRightOutline: {
      light: require("@/assets/truckFrontRightOutline.svg")
    },
    truckRearLeftOutline: {
      light: require("@/assets/truckRearLeftOutline.svg")
    },
    truckRearRightOutline: {
      light: require("@/assets/truckRearRightOutline.svg")
    },
    bicycleFrontLeft: {
      light: require("@/assets/bicycleFrontLeft.svg")
    },
    bicycleFrontRight: {
      light: require("@/assets/bicycleFrontRight.svg")
    },
    bicycleRearLeft: {
      light: require("@/assets/bicycleRearLeft.svg")
    },
    bicycleRearRight: {
      light: require("@/assets/bicycleRearRight.svg")
    },
    bicycleFrontLeftOutline: {
      light: require("@/assets/bicycleFrontLeftOutline.svg")
    },
    bicycleFrontRightOutline: {
      light: require("@/assets/bicycleFrontRightOutline.svg")
    },
    bicycleRearLeftOutline: {
      light: require("@/assets/bicycleRearLeftOutline.svg")
    },
    bicycleRearRightOutline: {
      light: require("@/assets/bicycleRearRightOutline.svg")
    },
    semitrailerFrontLeft: {
      light: require("@/assets/semitrailerFrontLeft.svg")
    },
    semitrailerFrontRight: {
      light: require("@/assets/semitrailerFrontRight.svg")
    },
    semitrailerRearLeft: {
      light: require("@/assets/semitrailerRearLeft.svg")
    },
    semitrailerRearRight: {
      light: require("@/assets/semitrailerRearRight.svg")
    },
    semitrailerFrontLeftOutline: {
      light: require("@/assets/semitrailerFrontLeftOutline.svg")
    },
    semitrailerFrontRightOutline: {
      light: require("@/assets/semitrailerFrontRightOutline.svg")
    },
    semitrailerRearLeftOutline: {
      light: require("@/assets/semitrailerRearLeftOutline.svg")
    },
    semitrailerRearRightOutline: {
      light: require("@/assets/semitrailerRearRightOutline.svg")
    },
    trailerFrontLeft: {
      light: require("@/assets/trailerFrontLeft.svg")
    },
    trailerFrontRight: {
      light: require("@/assets/trailerFrontRight.svg")
    },
    trailerRearLeft: {
      light: require("@/assets/trailerRearLeft.svg")
    },
    trailerRearRight: {
      light: require("@/assets/trailerRearRight.svg")
    },
    trailerFrontLeftOutline: {
      light: require("@/assets/trailerFrontLeftOutline.svg")
    },
    trailerFrontRightOutline: {
      light: require("@/assets/trailerFrontRightOutline.svg")
    },
    trailerRearLeftOutline: {
      light: require("@/assets/trailerRearLeftOutline.svg")
    },
    trailerRearRightOutline: {
      light: require("@/assets/trailerRearRightOutline.svg")
    },
    craneFrontLeft: {
      light: require("@/assets/craneFrontLeft.svg")
    },
    craneFrontRight: {
      light: require("@/assets/craneFrontRight.svg")
    },
    craneRearLeft: {
      light: require("@/assets/craneRearLeft.svg")
    },
    craneRearRight: {
      light: require("@/assets/craneRearRight.svg")
    },
    craneFrontLeftOutline: {
      light: require("@/assets/craneFrontLeftOutline.svg")
    },
    craneFrontRightOutline: {
      light: require("@/assets/craneFrontRightOutline.svg")
    },
    craneRearLeftOutline: {
      light: require("@/assets/craneRearLeftOutline.svg")
    },
    craneRearRightOutline: {
      light: require("@/assets/craneRearRightOutline.svg")
    },
    truckTrailerFrontLeft: {
      light: require("@/assets/truckTrailerFrontLeft.svg")
    },
    truckTrailerFrontRight: {
      light: require("@/assets/truckTrailerFrontRight.svg")
    },
    truckTrailerRearLeft: {
      light: require("@/assets/truckTrailerRearLeft.svg")
    },
    truckTrailerRearRight: {
      light: require("@/assets/truckTrailerRearRight.svg")
    },
    truckTrailerFrontLeftOutline: {
      light: require("@/assets/truckTrailerFrontLeftOutline.svg")
    },
    truckTrailerFrontRightOutline: {
      light: require("@/assets/truckTrailerFrontRightOutline.svg")
    },
    truckTrailerRearLeftOutline: {
      light: require("@/assets/truckTrailerRearLeftOutline.svg")
    },
    truckTrailerRearRightOutline: {
      light: require("@/assets/truckTrailerRearRightOutline.svg")
    },
    tractorUnitFrontLeft: {
      light: require("@/assets/tractorUnitFrontLeft.svg")
    },
    tractorUnitFrontRight: {
      light: require("@/assets/tractorUnitFrontRight.svg")
    },
    tractorUnitRearLeft: {
      light: require("@/assets/tractorUnitRearLeft.svg")
    },
    tractorUnitRearRight: {
      light: require("@/assets/tractorUnitRearRight.svg")
    },
    tractorUnitFrontLeftOutline: {
      light: require("@/assets/tractorUnitFrontLeftOutline.svg")
    },
    tractorUnitFrontRightOutline: {
      light: require("@/assets/tractorUnitFrontRightOutline.svg")
    },
    tractorUnitRearLeftOutline: {
      light: require("@/assets/tractorUnitRearLeftOutline.svg")
    },
    tractorUnitRearRightOutline: {
      light: require("@/assets/tractorUnitRearRightOutline.svg")
    },
    footerBackground: {
      light: require("@/assets/footerbackground.webp"),
      dark: require("@/assets/footerbackgrounddark.webp")
    },
    damageReportLogo: {
      light: require("@/assets/schadensmeldungLogo.svg"),
      dark: require("@/assets/schadensmeldungLogoDark.svg")
    },
    damageReportLogoIcon: {
      light: require("@/assets/damageReportLogoIcon.png")
    },
    numberplateEuropeDe: {
      light: require("@/assets/numberplateEuropeDe.svg")
    },
    numberplateEuropeGeneric: {
      light: require("@/assets/numberplateEuropeGeneric.svg")
    },
    numberplateBadge: {
      light: require("@/assets/numberplatePlakette.svg")
    },
    damagenotice: {
      light: require("@/assets/damagenotice.svg")
    },
    prevDamagenotice: {
      light: require("@/assets/prevDamagenotice.jpeg")
    },
    bookingSuccess: {
      light: require("@/assets/bookingSuccess.png")
    },
    outOfService: {
      light: require("@/assets/out-of-service.png")
    },
    image: {
      light: require("@/assets/undraw_images_re_0kll.svg")
    },
    confirmData: {
      light: require("@/assets/undraw_terms_re_6ak4 (1).svg")
    }
  };

  public static getAsset(isDarkTheme: boolean, assetType: AssetEnum) {
    if (assetType in this.ASSET_REPOSITORY) {
      const hasDarkTheme = this.ASSET_REPOSITORY[assetType].dark
        ? this.ASSET_REPOSITORY[assetType].dark
        : this.ASSET_REPOSITORY[assetType].light;
      return isDarkTheme ? hasDarkTheme : this.ASSET_REPOSITORY[assetType].light;
    }
  }
}

export enum AssetEnum {
  logo = "logo",
  mmmint = "mmmint",
  wirkaufendeinethg = "wirkaufendeinethg",
  overview = "overview",
  cockpit = "cockpit",
  cockpitOutline = "cockpitOutline",
  cockpitExample = "cockpitExample",
  cockpitInstruction = "cockpitInstruction",
  damageDetail = "damageDetail",
  damageDetailOutline = "damageDetailOutline",
  damageDetailExample = "damageDetailExample",
  damageDetailInstruction = "damageDetailInstruction",
  damage = "damage",
  damageOutline = "damageOutline",
  damageExample = "damageExample",
  damageInstruction = "damageInstruction",
  damageOverviewContainerInstruction = "damageOverviewContainerInstruction",
  damageOverviewBehindInstruction = "damageOverviewBehindInstruction",
  damageOverviewFrontInstruction = "damageOverviewFrontInstruction",
  carFrontLeft = "carFrontLeft",
  carFrontRight = "carFrontRight",
  carRearLeft = "carRearLeft",
  carRearRight = "carRearRight",
  carFrontLeftOutline = "carFrontLeftOutline",
  carFrontRightOutline = "carFrontRightOutline",
  carRearLeftOutline = "carRearLeftOutline",
  carRearRightOutline = "carRearRightOutline",

  containerFrontLeft = "containerFrontLeft",
  containerFrontRight = "containerFrontRight",
  containerRearLeft = "containerRearLeft",
  containerRearRight = "containerRearRight",
  containerFrontLeftOutline = "containerFrontLeftOutline",
  containerFrontRightOutline = "containerFrontRightOutline",
  containerRearLeftOutline = "containerRearLeftOutline",
  containerRearRightOutline = "containerRearRightOutline",

  tractorUnitFrontLeft = "tractorUnitFrontLeft",
  tractorUnitFrontRight = "tractorUnitFrontRight",
  tractorUnitRearLeft = "tractorUnitRearLeft",
  tractorUnitRearRight = "tractorUnitRearRight",
  tractorUnitFrontLeftOutline = "tractorUnitFrontLeftOutline",
  tractorUnitFrontRightOutline = "tractorUnitFrontRightOutline",
  tractorUnitRearLeftOutline = "tractorUnitRearLeftOutline",
  tractorUnitRearRightOutline = "tractorUnitRearRightOutline",

  damagenotice = "damagenotice",
  scene = "scene",
  noImage = "noImage",
  prevDamagenotice = "prevDamagenotice",

  motorbikeFrontLeft = "motorbikeFrontLeft",
  motorbikeFrontRight = "motorbikeFrontRight",
  motorbikeRearLeft = "motorbikeRearLeft",
  motorbikeRearRight = "motorbikeRearRight",
  motorbikeTop = "motorbikeTop",
  motorbikeFrontLeftOutline = "motorbikeFrontLeftOutline",
  motorbikeFrontRightOutline = "motorbikeFrontRightOutline",
  motorbikeRearLeftOutline = "motorbikeRearLeftOutline",
  motorbikeRearRightOutline = "motorbikeRearRightOutline",
  motorbikeTopOutline = "motorbikeTopOutline",

  craneFrontLeft = "craneFrontLeft",
  craneFrontRight = "craneFrontRight",
  craneRearLeft = "craneRearLeft",
  craneRearRight = "craneRearRight",
  craneFrontLeftOutline = "craneFrontLeftOutline",
  craneFrontRightOutline = "craneFrontRightOutline",
  craneRearLeftOutline = "craneRearLeftOutline",
  craneRearRightOutline = "craneRearRightOutline",

  truckTrailerFrontLeft = "truckTrailerFrontLeft",
  truckTrailerFrontRight = "truckTrailerFrontRight",
  truckTrailerRearLeft = "truckTrailerRearLeft",
  truckTrailerRearRight = "truckTrailerRearRight",
  truckTrailerFrontLeftOutline = "truckTrailerFrontLeftOutline",
  truckTrailerFrontRightOutline = "truckTrailerFrontRightOutline",
  truckTrailerRearLeftOutline = "truckTrailerRearLeftOutline",
  truckTrailerRearRightOutline = "truckTrailerRearRightOutline",

  truckFrontLeft = "truckFrontLeft",
  truckFrontRight = "truckFrontRight",
  truckRearLeft = "truckRearLeft",
  truckRearRight = "truckRearRight",
  truckFrontLeftOutline = "truckFrontLeftOutline",
  truckFrontRightOutline = "truckFrontRightOutline",
  truckRearLeftOutline = "truckRearLeftOutline",
  truckRearRightOutline = "truckRearRightOutline",

  motorbikeFront = "motorbikeFront",
  motorbikeLeft = "motorbikeLeft",
  motorbikeRight = "motorbikeRight",
  motorbikeRear = "motorbikeRear",
  motorbikeFrontOutline = "motorbikeFrontOutline",
  motorbikeLeftOutline = "motorbikeLeftOutline",
  motorbikeRightOutline = "motorbikeRightOutline",
  motorbikeRearOutline = "motorbikeRearOutline",

  bicycleFrontLeft = "bicycleFrontLeft",
  bicycleFrontRight = "bicycleFrontRight",
  bicycleRearLeft = "bicycleRearLeft",
  bicycleRearRight = "bicycleRearRight",
  bicycleFrontLeftOutline = "bicycleFrontLeftOutline",
  bicycleFrontRightOutline = "bicycleFrontRightOutline",
  bicycleRearLeftOutline = "bicycleRearLeftOutline",
  bicycleRearRightOutline = "bicycleRearRightOutline",

  registration = "registration",
  registrationSchema = "registrationSchema",
  registrationBack = "registrationBack",
  registrationBackOutline = "registrationBackOutline",
  registrationExample = "registrationExample",
  registrationCorrectInstructions = "registrationCorrectInstructions",
  registrationIncorrectInstructions = "registrationIncorrectInstructions",
  registrationIncorrectInstructionsPlaceholder = "registrationIncorrectInstructionsPlaceholder",
  registrationCorrectInstructionsPlaceholder = "registrationCorrectInstructionsPlaceholder",

  semitrailerFrontLeft = "semitrailerFrontLeft",
  semitrailerFrontRight = "semitrailerFrontRight",
  semitrailerRearLeft = "semitrailerRearLeft",
  semitrailerRearRight = "semitrailerRearRight",
  semitrailerFrontLeftOutline = "semitrailerFrontLeftOutline",
  semitrailerFrontRightOutline = "semitrailerFrontRightOutline",
  semitrailerRearLeftOutline = "semitrailerRearLeftOutline",
  semitrailerRearRightOutline = "semitrailerRearRightOutline",

  trailerFrontLeft = "trailerFrontLeft",
  trailerFrontRight = "trailerFrontRight",
  trailerRearLeft = "trailerRearLeft",
  trailerRearRight = "trailerRearRight",
  trailerFrontLeftOutline = "trailerFrontLeftOutline",
  trailerFrontRightOutline = "trailerFrontRightOutline",
  trailerRearLeftOutline = "trailerRearLeftOutline",
  trailerRearRightOutline = "trailerRearRightOutline",

  registrationAt = "registrationAt",
  registrationAtSchema = "registrationAtSchema",
  registrationAtBack = "registrationAtBack",
  registrationAtBackSchema = "registrationAtBackSchema",
  registrationAtExample = "registrationAtExample",
  registrationAtCorrectInstructions = "registrationAtCorrectInstructions",
  registrationAtIncorrectInstructions = "registrationAtIncorrectInstructions",
  registrationAtIncorrectInstructionsPlaceholder = "registrationAtIncorrectInstructionsPlaceholder",
  registrationAtCorrectInstructionsPlaceholder = "registrationAtCorrectInstructionsPlaceholder",

  numberplate = "numperplate",
  numberplateEuropeGeneric = "numberplateEuropeGeneric",
  numberplateBadge = "numberplateBadge",
  numberplateMotorbike = "numberplateMotorbike",
  numberplateEuropeDe = "numberplateEuropeDe",

  tuev = "tuev",
  tire = "tire",
  tireExample = "tireExample",
  tireExampleAlt = "tireExampleAlt",
  tireExampleOutlined = "tireExampleOutlined",

  vinExample = "vinExample",
  vinExmapleOutlined = "vinExmapleOutlined",

  driversLicenseExampleFront = "driversLicenseExampleFront",
  driversLicenseExampleFrontOutlined = "driversLicenseExampleFrontOutlined",

  driversLicenseExampleBack = "driversLicenseExampleBack",
  driversLicenseExampleBackOutlined = "driversLicenseExampleBackOutlined",

  emptyData = "emptyData",
  loading = "loading",
  forest = "forest",
  charging = "charging",
  towingTruck = "towingTruck",
  fixingBugs = "fixingBugs",
  qaEngineer = "qaEngineer",
  warning = "warning",
  serverDown = "serverDown",

  damageReportLogo = "damageReportLogo",
  damageReportLogoIcon = "damageReportLogoIcon",

  footerBackground = "footerBackground",
  bookingSuccess = "bookingSuccess",
  outOfService = "outOfService",

  image = "image",

  confirmData = "confirmData"
}

interface AssetTheme {
  light: any;
  dark?: any;
}
