import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { PartnerModule } from "@/store/modules/partner";
import {
  ThgBaseChartItemListViewmodelGen,
  ThgBaseTrendAnalysisViewmodelGen,
  ThgCustomerDataViewModelGen,
  ThgThgImpactTypeAnalyticsViewmodelGen,
  ThgThgPartnerAnalysisViewmodelGen,
  ThgThgRealtimeAnalysisViewmodelGen,
  ThgThgStatusDistributionAnalyticsViewmodelGen,
  ThgThgUserStatsViewmodelGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { ThgAnalytics } from "../v1/ThgAnalytics";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

/**
 * Communicates with the thg module (implementet in thg api)
 */
class AnalyticsService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: ThgAnalytics;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new ThgAnalytics(this.client);
  }

  async getAvailableCountries(): Promise<string[]> {
    const response = await this.proxy.thgAnalyticsControllerGetAvailableCountries();
    return response.data;
  }

  async getAvailableYears(): Promise<number[]> {
    const response = await this.proxy.thgAnalyticsControllerGetAvailableYears();
    return response.data;
  }

  /**
   *
   * Gets the RealTimeAnalytics Data
   *
   * @returns
   */
  async getRealtimeData(): Promise<ThgThgRealtimeAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerRealtime();
    return response.data;
  }

  /**
   *
   * Gets the TrendAnalytics Data
   *
   * @returns
   */
  async getTrendanalysisData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerTrendAnalysis({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the PartnerDistribution Data
   *
   * @returns
   */
  async getPartnerDistributionData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgThgPartnerAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerPartnerdistribution({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the weekday distribution Data
   *
   * @returns
   */
  async getWeekdayAnalysis(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerWeekdayAnalysis({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the hout distribution Data
   *
   * @returns
   */
  async getHourAnalysis(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerHourAnalysis({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the StatiDistribution Data
   *
   * @returns
   */
  async getStatusDistribution(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgThgStatusDistributionAnalyticsViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerStatiDistribution({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the impact type distribution Data
   *
   * @returns
   */
  async getImpactTypeDistribution(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgThgImpactTypeAnalyticsViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerImpactTypeDistribution({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the AffiliateDistribuiton Data
   *
   * @returns
   */
  async getAffiliateDistributionData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerAffiliatedistribution({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the Vehicle Class Distributoon Data
   *
   * @returns
   */
  async getVehicleClassDistributionData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerVehicleClassDistribution({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the Year Distributoon Data
   *
   * @returns
   */
  async getYearDistributionData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerYeardistribution({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the Year Distributoon Data per Partner
   *
   * @returns
   */
  async getYearDistributionDataPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerYeardistributionPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the Year Distributoon Data
   *
   * @returns
   */
  async getIsMultiYearDistributionData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerIsMultiyearDistribution({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the Year Distributoon Data per Partner
   *
   * @returns
   */
  async getIsMultiYearDistributionDataaPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerIsMultiyeardistributionPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the RealTimeAnalytics Data per Partner
   *
   * @returns
   */
  async getRealtimeDataPerPartner(): Promise<ThgThgRealtimeAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerRealtimeForPartner(
      PartnerModule.partner.id || PartnerModule.partner._id
    );
    return response.data;
  }

  /**
   *
   * Gets the TrendAnalytics Data per Partner
   *
   * @returns
   */
  async getTrendanalysisDataPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerTrendAnalysisForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the TrendAnalytics Data per Partner
   *
   * @returns
   */
  async getMeterReadingTrendanalysisDataPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerMeterReadingtrendAnalysisForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the Statusdistribution per Partner
   *
   * @returns
   */
  async getStatusDistributionPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgThgStatusDistributionAnalyticsViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerStatiDistributionForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the impact type distribution Data  per Partner
   *
   * @returns
   */
  async getImpactTypeDistributionPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgThgImpactTypeAnalyticsViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerImpactTypeDistributionForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the AffiliateDistribuiton Data  per Partner
   *
   * @returns
   */
  async getAffiliateDistributionDataPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerAffiliateDistributionForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the Vehicle Class Distributoon Data  per Partner
   *
   * @returns
   */
  async getVehicleClassDistributionDataPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerVehicleClassDistributionForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the week day distribution for a specific partner
   *
   * @returns
   */
  async getWeekdayAnalysisPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerWeekdayAnalysisPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the week day distribution for a specific partner
   *
   * @returns
   */
  async getHourAnalysisPerPartner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerHourAnalysisPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the registered vins per partner
   *
   * @returns
   */
  async getVinAnalysisPerPArtner(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerVinAnalysisPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the customerdata by partnerId
   *
   * @returns
   */
  async getCustomerDataByPartnerId(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgCustomerDataViewModelGen> {
    const response = await this.proxy.thgAnalyticsControllerCustomerDataByPartnerId({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the user realtime Analytics Data
   *
   * @returns
   */
  async getUserRealTimedata(): Promise<ThgThgRealtimeAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerUserRealTime();

    return response.data;
  }

  /**
   *
   * Gets the TrendAnalytics Data
   *
   * @returns
   */
  async getUserTrendanalysisData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerUserTrendAnalysis({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the  b2b|b2c distribution
   *
   * @returns
   */
  async getUserAnalysisCompanyDistribution(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerUserIsCompany({
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the  b2b|b2c distribution
   *
   * @returns
   */
  async getUserMarketingOptinDistribution(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseChartItemListViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerUserMarketingOptIn({
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the  distribution of thg per user
   *
   * @returns
   */
  async getThgPerUserDistribution(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgThgUserStatsViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerThgPerUserDistribution({
      from,
      to,
      filter
    });
    return response.data;
  }

  /**
   *
   * Gets the TrendAnalytics Data for MeterReadings
   *
   * @returns
   */
  async getMeaterReadingTrendanalysisData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerMeterReadingTrendAnalysis({ from, to, filter });
    return response.data;
  }

  /**
   *
   * Gets the TrendAnalytics Data for thg promotions
   *
   * @returns
   */
  async getThgPromotionTrendanalysisData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerThgPromotionTrendAnalysis({ from, to, filter });
    return response.data;
  }

  async getThgPromotionCountAnalysisData(
    from: string,
    to: string,
    filter?: IPageFilterElement[]
  ): Promise<ThgBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.thgAnalyticsControllerThgPromotionCountTrendAnalysis({ from, to, filter });
    return response.data;
  }
}

export default new AnalyticsService(new ThgHttpClientProvider());
