import { IsFilterable, Filter } from "@/lib/filterable";
import { MrfiktivRegistrationDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class RegistrationBase implements MrfiktivRegistrationDtoGen {
  /**
   * @inheritdoc
   */
  firstname?: string;

  /**
   * @inheritdoc
   */
  name?: string;

  /**
   * @inheritdoc
   */
  street?: string;

  /**
   * @inheritdoc
   */
  zipCode?: string;

  /**
   * @inheritdoc
   */
  city?: string;

  /**
   * @inheritdoc
   */
  huYear?: string;

  /**
   * @inheritdoc
   */
  huMonth?: string;

  /**
   * @inheritdoc
   */
  manufacturerNameCode?: string;

  /**
   * @inheritdoc
   */
  manufacturerTypeCode?: string;

  /**
   * @inheritdoc
   */
  driveTyp?: string;

  /**
   * @inheritdoc
   */
  identificationnumber?: string;

  /**
   * @inheritdoc
   */
  numberplate?: string;

  /**
   * @inheritdoc
   */
  firstregistrationDay?: string;

  /**
   * @inheritdoc
   */
  firstregistrationMonth?: string;

  /**
   * @inheritdoc
   */
  firstregistrationYear?: string;

  /**
   * @inheritdoc
   */
  manufacturerName?: string;

  /**
   * @inheritdoc
   */
  manufacturerType?: string;

  /**
   * @inheritdoc
   */
  manufacturerDescription?: string;

  /**
   * @inheritdoc
   */
  vehicleClass?: string;

  constructor(registration?: Partial<MrfiktivRegistrationDtoGen>) {
    this.firstname = registration?.firstname ?? "";
    this.name = registration?.name ?? "";
    this.street = registration?.street ?? "";
    this.zipCode = registration?.zipCode ?? "";
    this.city = registration?.city ?? "";
    this.huYear = registration?.huYear ?? "";
    this.huMonth = registration?.huMonth ?? "";
    this.manufacturerNameCode = registration?.manufacturerNameCode;
    this.manufacturerTypeCode = registration?.manufacturerTypeCode;
    this.driveTyp = registration?.driveTyp;
    this.identificationnumber = registration?.identificationnumber ?? "";
    this.numberplate = registration?.numberplate ?? "";
    this.firstregistrationDay = registration?.firstregistrationDay ?? "";
    this.firstregistrationMonth = registration?.firstregistrationMonth ?? "";
    this.firstregistrationYear = registration?.firstregistrationYear;
    this.manufacturerName = registration?.manufacturerName ?? "";
    this.manufacturerType = registration?.manufacturerType ?? "";
    this.manufacturerDescription = registration?.manufacturerDescription ?? "";
    this.vehicleClass = registration?.vehicleClass ?? "";
  }
}
type IRegistration = RegistrationBase;
const Registration = Filter.createForClass(RegistrationBase);

export { IRegistration, Registration };
